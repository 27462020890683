import { Injectable } from '@angular/core';
import { ApiHandlerService } from '../services/api-handler.service';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';
import { Grades } from '../models/grades.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GradesService {

  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;

  constructor(private apiHandlerService: ApiHandlerService,
      private httpClient: HttpClient) {
      this.apiEndpointdonfig = new ApiEndpointConfig();
      this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();  
  }
 /**
   * 
   * get list of boards
   */
   getListOfBoards(){
     const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'boards';
     return this.apiHandlerService.get(url);
   }

   getListOfLanguage(){
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'language/list';
    return this.apiHandlerService.get(url);
  }
   

    /**
   * 
   * get list of Grades
   */
    
  getGradeList(){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'grades';
    return this.apiHandlerService.get(url);
  }

  /**
   * 
   * @param grades create grades 
   */
  createGrade(grades: Grades):any {
    localStorage.setItem('requireToken', 'true');
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'grades';
    return this.apiHandlerService.save(url, grades);
  };
  /**
  * Get grade by gradeId
  */
 getGradeById(gradeId){
  let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'grade/'+gradeId ;
  return this.apiHandlerService.get(url);
}
/**
   * 
   * Update Grade
   */

  updateGrade(formData: any, gradeId) {
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'grade/'+gradeId;
    return this.httpClient.post(url,formData)
  }
   /**
   * 
   * Delete Grades
   */
    
  deleteGrade(id){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'grade/'+id;
    return this.apiHandlerService.delete(url);
  }
  
}
