import { Component, OnInit } from "@angular/core";
import { Schools } from "../../shared/models/schools.model";
import { FormControl, Validators } from "@angular/forms";
import { SchoolsService } from "../../shared/services/schools.service";
import { AlertService } from "../../shared/services/alert.service";
import { Router } from "@angular/router";
import { GradesService } from "../../shared/services/grades.service";
import { LanguageService } from "../../shared/services/languages.service";
import { SchoolTypesService } from "./school-types/school-types.service";
import { SchoolChainsService } from "../school-chain/school-chains.service";

@Component({
	selector: "app-schools",
	templateUrl: "./schools.component.html",
	styleUrls: ["./schools.component.scss"]
})
export class SchoolsComponent implements OnInit {
	public schools: Schools;
	public school: FormControl;
	public isSubmitted = false;
	public school_name: any;
	public city: any;
	public state: any;
	public admin_first_name: any;
	public admin_last_name: any;
	public admin_email: any;
	public admin_mobile: any;
	public username: any;
	public password: any;
	public board_id: any;
	public language_id: any;
	public school_type: any;
	public school_chain_id: any;
	public district: any;
	languages;
	boards;
	schoolChain;
	schoolTypeList;

	constructor(
		private schoolsService: SchoolsService,
		private alertService: AlertService,
		private gradesService: GradesService,
		private router: Router,
		private langServices: LanguageService,
		private schooltyser: SchoolTypesService,
		private scholserv: SchoolChainsService
	) {
		this.schools = new Schools();
	}

	ngOnInit() {
		this.getBoardList();
		this.createFormControls();
		this.getLanguageList();
		this.getSchoolData();
		this.getSchoolchaindata();
		console.log("school Chain...");
	}

	createFormControls(): any {
		this.board_id = new FormControl("", [Validators.required]);
		this.language_id = new FormControl("", [Validators.required]);
		this.school_type = new FormControl("", [Validators.required]);
		this.school_name = new FormControl("", [Validators.required]);
		this.city = new FormControl("", [Validators.required]);
		this.state = new FormControl("", [Validators.required]);
		this.admin_first_name = new FormControl("", [Validators.required]);
		this.admin_last_name = new FormControl("", [Validators.required]);
		this.admin_email = new FormControl("", [Validators.required]);
		this.admin_mobile = new FormControl("", [Validators.required]);
		this.username = new FormControl("", [Validators.required]);
		this.password = new FormControl("", [Validators.required]);
		this.school = new FormControl("", [Validators.required]);
		this.school_chain_id = new FormControl("", [Validators.required]);
		this.district = new FormControl("", [Validators.required]);
	}
	/**
	 * Validate form fields
	 */
	validateFormFields(): any {
		if (
			!this.school_name.invalid &&
			!this.city.invalid &&
			!this.state.invalid &&
			!this.admin_first_name.invalid &&
			!this.admin_last_name.invalid &&
			!this.admin_email.invalid &&
			!this.admin_mobile.invalid &&
			!this.username.invalid &&
			!this.password.invalid &&
			!this.language_id.invalid &&
			!this.school_type.invalid &&
			!this.district.invalid &&
			!this.school_chain_id.invalid
		) {
			return true;
		} else {
			return false;
		}
	}
	/**
	 * Creats a School form data
	 */
	createSchoolFormData(): any {
		this.schools.board_id = this.board_id.value;
		this.schools.language_id = this.language_id.value;
		this.schools.school_name = this.school_name.value;
		this.schools.city = this.city.value;
		this.schools.state = this.state.value;
		this.schools.admin_first_name = this.admin_first_name.value;
		this.schools.admin_last_name = this.admin_last_name.value;
		this.schools.admin_email = this.admin_email.value;
		this.schools.admin_mobile = this.admin_mobile.value;
		this.schools.username = this.username.value;
		this.schools.password = this.password.value;
		this.schools.school_type_id = this.school_type.value;
		this.schools.district = this.district.value;
		this.schools.school_chain_id = this.school_chain_id.value;
	}

	/**
	 * Get board list
	 */
	getBoardList() {
		this.gradesService.getListOfBoards().subscribe(
			(data: any) => {
				this.boards = data.data;
			},
			(response: any) => {
				console.log(response);
			}
		);
	}

	/**
	 * Get language list
	 */
	getLanguageList() {
		this.langServices.getListOfLanguage().subscribe(
			(data: any) => {
				this.languages = data.data;
			},
			(response: any) => {
				console.log(response);
			}
		);
	}

	/**
	 *  Get school type list
	 */
	getSchoolData() {
		this.schooltyser.getSchoolTypeList().subscribe(Response => {
			if (Response.status == "success") {
				this.schoolTypeList = Response.data;

				console.log(this.schoolTypeList);
			}
		});
	}

	/**
	 *  Get school chain list
	 */
	getSchoolchaindata() {
		this.scholserv.getSchoolChainList().subscribe(Response => {
			if (Response.status == "success") {
				this.schoolChain = Response.data;

				console.log(this.schoolTypeList);
			}
		});
	}

	/**
	 * create Schools
	 */
	createSchools() {
		this.isSubmitted = true;
		if (this.validateFormFields()) {
			this.createSchoolFormData();
			this.schoolsService
				.createSchools(this.schools)
				.subscribe((response: any) => {
					if (response.body.status == "success") {
						this.alertService.success(response.body.message);
						this.router.navigate(["/schools-list"]);
					} else if (response.body.status == "error") {
						this.alertService.error(response.body.message);
					} else {
						this.alertService.error("Internal Server Error");
					}
				});
		}
	}
	/**
	 * cancel form
	 */
	cancel() {
		this.router.navigate(["/schools-list"]);
	}
}
