export class Schools {
    school_name: string;
    city: string;
    state: string;
    admin_first_name: string;
    admin_last_name: string;
    admin_email: string;
    admin_mobile: string;
    username: string;
    password: string;
    board_id: any = '';
    language_id: any = '';
    school_type_id: any = '';
    school_chain_id: any = '';
    district: string;

    constructor() {
        this.school_name = "";
        this.city = "";
        this.state = "";
        this.admin_first_name = "";
        this.admin_last_name = "";
        this.admin_email = "";
        this.admin_mobile = "";
        this.username = "";
        this.password = "";
        this.board_id = "";
        this.language_id = ""
        this.school_type_id = ""
        this.school_chain_id = ""
        this.district = ""

    }
}
