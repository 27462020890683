import { Injectable } from '@angular/core';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';
import { ApiHandlerService } from '../services/api-handler.service';
import { Schools } from '../models/schools.model';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class SchoolsService {

  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;
  constructor(
    private httpClient: HttpClient,
    private apiHandlerService: ApiHandlerService) {
    this.apiEndpointdonfig = new ApiEndpointConfig();
    this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();
    }
  /**
  * 
  * Get list of Schools in details 
  */

 getSchoolsList() {
  let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'schools';
  return this.apiHandlerService.get(url);
 }

 /**
  * 
  * Schools get details 
  */

  getSchoolById(schoolId){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'school/'+schoolId ;
    return this.apiHandlerService.get(url);

  }

 /**
   * 
   * @param grades create Schools
   */
  createSchools(schools: Schools):any {
    localStorage.setItem('requireToken', 'true');
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'schools';
    return this.apiHandlerService.save(url, schools);
  };
  /**
   * 
   * Update schools
   */

  updateSchool(formData: any, schoolId) {
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'school/'+schoolId;
    return this.httpClient.post(url,formData)
  }
  
  /**
   * 
   * Delete schools
   */

  deleteSchool(id){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'school/'+id;
    return this.apiHandlerService.delete(url);

  }

}

