import { Injectable } from '@angular/core';
import { ApiHandlerService } from '../services/api-handler.service';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';
import { UserProfile } from '../models/user-profile.model';

@Injectable()


export class UserProfileService {
  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;

  constructor(private apiHandlerService: ApiHandlerService) {
    this.apiEndpointdonfig = new ApiEndpointConfig();
    this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();
  }

  /**
   * 
   * get list of Grades
   */
  
  getUserProfile(){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'user/profile';
    return this.apiHandlerService.get(url);
  }

   /**
   * 
   * Update User Profile
   */

  updateUserProfile(userprofile: UserProfile): any{
    localStorage.setItem('requireToken', 'true'); 
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'user/profile';
    return this.apiHandlerService.save(url, userprofile);

  }
}
