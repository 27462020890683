import { Component, OnInit } from '@angular/core';
import { GradesService } from '../../shared/services/grades.service';
import { SubjectsService } from '../../shared/services/subjects.service';
import { ChaptersService } from '../../shared/services/chapters.service';
import { LeaderboardService } from '../../shared/services/leaderboard.service';
import { ContentSetupService } from '../../shared/services/content-setup.service';
import { AlertService } from '../../shared/services/alert.service';
import { Router } from '@angular/router';
import { SessionService } from '../../shared/services/session.service';
import { ExcelService } from '../../shared/services/excel.service';
@Component({
  selector: 'app-performance-report',
  templateUrl: './performance-report.component.html',
  styleUrls: ['./performance-report.component.scss']
})
export class PerformanceReportComponent implements OnInit {

  public boards: any;
  public grades: any;
  public chapters: any;
  public schools: any;
  public subjects: any;
  public selectedBoard: any = "";
  public selectedSubject: any = "";
  public selectedGrade: any = "";
  public selectedSchool: any = "";
  public selectedChapter: any = "";
  public allRank = [];
  public filltered = false;
  public schoolCount: any;
  public boardCount: any;
  public gradeCount: any;
  public subjectCount: any;
  public chapterCount: any;
  public schoolGrade: any;
  p: number = 1;

  //sorting table 
  key: string = 'username';'subject_name';'chapter_name';'percentage';'grade';
  reverse: boolean = false;


  constructor(private gradesService: GradesService,
    private router: Router,
    public chapterservice: ChaptersService,
    public subjectService: SubjectsService,
    public contentsetupservice: ContentSetupService,
    private alertService: AlertService,
    private sessionService: SessionService,
    public ExcelService: ExcelService,
    public LeaderboardService: LeaderboardService) {

  }

  ngOnInit() {
    this.getBoardList();
    this.getGradeSchool();
 
  }

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  /**
   * Get Board List 
   */

  getBoardList() {
    this.gradesService.getListOfBoards().subscribe((data: any) => {
      this.boards = data.data;
    }, (response: any) => {
      this.boards = [];
    });
  };

  /**
   * Get Grade List 
   */
  getGrades() {
    this.grades = this.schools = this.subjects = this.chapters = [];
    this.selectedGrade = this.selectedSchool = this.selectedSubject = this.selectedChapter = "";
    if(this.selectedBoard > 0){
      this.subjectService.getGrades(this.selectedBoard).subscribe((data: any) => {
        this.grades = data.data;
      }, (response: any) => {
            
      });  
    }
    

  };

  /**
   * Get Schools List 
   */
  getSchools() {
    if(this.selectedBoard>0){
      this.LeaderboardService.getSchools(this.selectedBoard).subscribe((data: any) => {
        this.schools = data.data;
      }, (response: any) => {
        // sthis.schools = [];
      });
    }
    
  };

  /**
   * Get Subject List 
   */
  getSubjects() {
    this.subjects = this.chapters = [];
    this.selectedSubject = this.selectedChapter = "";
    
    if(this.selectedGrade > 0){
      this.subjectService.getSubjects(this.selectedGrade).subscribe((data: any) => {
        this.subjects = data.data;
      }, (response: any) => {
          
      });

    }
  
  };

  /**
   * Get List of chapter
   */

  getChapterList() {
    this.chapters = [];
    this.selectedChapter = "";
    if(this.selectedSubject > 0){
      this.contentsetupservice.getChapterList(this.selectedSubject).subscribe((data: any) => {
        this.chapters = data.data;
      }, (response: any) => {
        
      });
    }
     
  };

  /**
   * Get Percentage report to show in tabular form
   */

  getPercentageReport() {
    let params =
    {
      board_id: this.selectedBoard,
      school_id: this.selectedSchool,
      grade_id: this.selectedGrade,
      subject_id: this.selectedSubject,
      chapter_id: this.selectedChapter,
    }

    if (this.selectedBoard) {
      params.board_id = this.selectedBoard
    } if (this.selectedGrade) {
      params.grade_id = this.selectedGrade
    } if (this.selectedSchool) {
      params.school_id = this.selectedSchool
    } if (this.selectedSubject) {
      params.subject_id = this.selectedSubject
    } if (this.selectedChapter) {
      params.chapter_id = this.selectedChapter
    }

    this.filltered = false;
    this.LeaderboardService.getPercentageReport(params).subscribe((data: any) => {
      this.allRank = data.data;
      this.schoolCount = data.school_count;
      this.boardCount = data.board_count;
      this.gradeCount = data.grade_count;
      this.subjectCount = data.subject_count;
      this.chapterCount = data.chapter_count;
      this.filltered = true;
    }, (response: any) => {
      if (response.status == "success") {
        this.alertService.success(response.message);

      }
      else if (response.status == "error") {  
        this.alertService.error(response.message);
        this.allRank = [];
        
      }
      else {
        this.alertService.error("Internal Server Error");
      }

      this.filltered = true;
      // this.allRank = [];
    }
    );

  }

  /**
   * Get Gradelist from school(school token)
   */

  getGradeSchool() {
    this.LeaderboardService.getGradeschool().subscribe((data: any) => {
      this.schoolGrade = data.data;
    }, (response: any) => {
    });

  }

  /**
   * Get Percentage report for school
   */

  getSchoolPercentageReport() {

    let params =
    {
      grade_id: this.selectedGrade,
      subject_id: this.selectedSubject,
      chapter_id: this.selectedChapter,

    }

     if (this.selectedGrade) {
      params.grade_id = this.selectedGrade
    } if (this.selectedSubject) {
      params.subject_id = this.selectedSubject
    } if (this.selectedChapter) {
      params.chapter_id = this.selectedChapter
    }

    this.filltered = false;
    this.LeaderboardService.getSchoolPercentageReport(params).subscribe((data: any) => {
      this.allRank = data.data;
      this.schoolCount = data.school_count;
      this.gradeCount = data.grade_count;
      this.subjectCount = data.subject_count;
      this.chapterCount = data.chapter_count;
      this.filltered = true;
    }, (response: any) => {
      if (response.status == "success") {
        this.alertService.success(response.message);

      }
      else if (response.status == "error") {
        this.alertService.error(response.message);
        this.allRank = [];
      }
      else {
        this.alertService.error("Internal Server Error");
      }

      this.filltered = true;
       this.allRank = [];
    }
    );

  }

  /**
   *
   * User Csv Report
   * 
   */
  
//   exportAsXLSX():void {
//     this.ExcelService.exportAsExcelFile(this.allRank, 'performance report');
//  }

 exportAsXLSX():void { 
  let data = this.allRank;
  for(let i=0; i < this.allRank.length; i++){
    delete data[i]['id'];
    delete data[i]['board_id']
    delete data[i]['subject_id']
    delete data[i]['grade_id']
    delete data[i]['chapter_id']
}
  this.ExcelService.exportAsExcelFile(data, 'performance report');
}


}
