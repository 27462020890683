import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppRouteUrls } from '../../shared/models/app-routes.model';
import { AlertService } from '../../shared/services/alert.service';
import { ValidationService } from '../../shared/services/validation.service';
import { ControlMessagesComponent } from '../shared/control-messages/control-messages.component';
import { LoginService } from '../../shared/services/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',  
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public email: FormControl;

  constructor(private loginService: LoginService,
              private router: Router,
              private alertService: AlertService) { }

  ngOnInit() {
    this.createFormControls();
  }
  /**
   * Creates form controls 
   */
  createFormControls(): void {
    this.email = new FormControl("", [
      Validators.required, 
      ValidationService.emailValidator,
    ]);
  };

  /**
   * Forgot password
   */
  forgotPassword(): void {
    if(!this.email.invalid){
      let payloadData = {"Email":""};
      payloadData.Email = this.email.value;
      this.loginService.forgotPassword(payloadData).subscribe((data: any) => {
        if(data){
          this.router.navigate([AppRouteUrls.LOGIN]);
          this.alertService.success(data.success.message);
        }
      }, (error: any) =>{
        this.alertService.error(error.error.message);
      })
    };
  };
} 