import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppRouteUrls } from '../../shared/models/app-routes.model';
import { AlertService } from '../../shared/services/alert.service';
import { ValidationService } from '../../shared/services/validation.service';
import { ControlMessagesComponent } from '../shared/control-messages/control-messages.component';
import { LoginService } from '../../shared/services/login.service';
import { SessionService } from '../../shared/services/session.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public invalidUrl: boolean;
  public password: FormControl;
  public confirmPassword: FormControl;
  public urlCode: string;
  public urlExpiry: string;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private loginService: LoginService,
              private alertService: AlertService,
              private sessionService: SessionService) {
              
                this.route.params.subscribe( params => {
                   // this.checkValidCode(params['code']); 
                   this.urlCode = params['code'];
                   this.urlExpiry = params['expiry'] + "==";
                });
                this.invalidUrl = false;
  }

  ngOnInit() {
    console.log("URLS PARms ", this.urlCode, this.urlExpiry);
    if((this.urlCode)  && (this.urlExpiry && this.urlExpiry !== 'undefined==')){
      this.checkValidCodeExpiry(this.urlCode, this.urlExpiry);
    } else {
      this.checkValidCode(this.urlCode);
    }
    
    this.createFormControls();
  }

  /**
   * Creates form controls 
   */
  createFormControls(): void {
    this.password = new FormControl("", [
      Validators.required, 
    ]);
    this.confirmPassword = new FormControl("", [
      Validators.required, 
    ]);
  };


  /**
   * Check for valid user and expiry and code
   */
  checkValidCodeExpiry(code:string, expiry:string): void {
    this.loginService.verifyUser(code, expiry).subscribe((data : any) => {
      // this.alertService.success(data.success.message);
      this.alertService.success("Please set the password"); 
      this.sessionService.resetSession();
   }, (error : any) => { 
     this.sessionService.resetSession();
     this.invalidUrl = true;
     this.alertService.error(error.error.message);
    });
  };

  /**
   * Check code valid url or not status
   */
  checkValidCode(code:string): void {
    this.loginService.checkValidCodeForResetPassword(code).subscribe((data : any) => {
      // this.alertService.success(data.success.message);
      this.alertService.success("Please set the password");
      this.sessionService.resetSession();
   }, (error : any) => { 
     this.sessionService.resetSession();
     this.invalidUrl = true;
     this.alertService.error(error.error.message);
    });
  };

  /**
   * Reset password
   */
  resettPassword(): void {
    if(!this.password.invalid && !this.confirmPassword.invalid){
      if(this.urlCode && this.urlExpiry){
        this.loginService.setPassword(this.password.value, this.confirmPassword.value, this.urlCode).subscribe((data: any) => {
          if(data){
            this.router.navigate([AppRouteUrls.LOGIN]);
            this.alertService.success(data.success.message);
            this.sessionService.resetSession();
          }
        }, (error: any) =>{
          this.alertService.error(error.error.message);
          this.sessionService.resetSession();
        })
      }
      if(this.urlCode && !this.urlExpiry) {
        let payloadData = {"password":""};
         payloadData.password = this.confirmPassword.value;
        this.loginService.resetPassword(payloadData, this.urlCode).subscribe((data: any) => {
          if(data){
            this.router.navigate([AppRouteUrls.LOGIN]);
            this.alertService.success(data.success.message);
            this.sessionService.resetSession();
          }
        }, (error: any) =>{
          this.alertService.error(error.error.message);
          this.sessionService.resetSession();
        })
      }
    };
  };
}
