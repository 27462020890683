import { Injectable } from '@angular/core';
import { ChangePassword} from '../../shared/models/change-password.model';
import { ApiHandlerService } from '../services/api-handler.service';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';

@Injectable()

export class ChangePasswordService {

  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;

  constructor(private apiHandlerService: ApiHandlerService) {
    this.apiEndpointdonfig = new ApiEndpointConfig();
    this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();
   }

  updatePassword(changepassword : ChangePassword): any{
    localStorage.setItem('requireToken', 'true');
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'update/user/password';
    return this.apiHandlerService.save(url, changepassword);
  }
}
