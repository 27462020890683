import { Component, OnInit, Injectable } from '@angular/core';
import { UtilityService } from '../../shared/services/utility.service';
import { SessionService } from '../../shared/services/session.service';
import { Router } from '@angular/router'
import { from } from 'rxjs/observable/from';


@Injectable()
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
} )
export class SideBarComponent implements OnInit {
  sideBarClassName = 'col-md-2 col-sm-2';
  constructor(private utilityService: UtilityService, 
    public sessionService: SessionService,
    private router:Router
    ) {
    utilityService.sidebarMinimizeButton.subscribe(res => {
      this.sideBarClassName = res;
      if (this.sideBarClassName === 'col-md-2 col-sm-2') {
        document.getElementById('footer').style.width = '15%';
      } else if (this.sideBarClassName === 'col-md-1 col-sm-1') {
        document.getElementById('footer').style.width = '3%';
      }
    });
   }

  ngOnInit() {
  }

  onMinimize() {
    this.utilityService.isSideBarMinimize.emit();
  }

}
