import { Injectable } from '@angular/core';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';
import { ApiHandlerService } from '../services/api-handler.service';
import { AlertService } from '../../shared/services/alert.service';
@Injectable()
export class ContentSetupService {
  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;
  constructor( private apiHandlerService: ApiHandlerService,) { 
  this.apiEndpointdonfig = new ApiEndpointConfig();
  this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();
 
}

   /**
   * Get chapter list
   */
getChapterList(subject_id){
  const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'chapters?subject_id=' +subject_id;
  return this.apiHandlerService.get(url);
}

 /**
   * Get Topic List
   */
getTopicsList(){
  const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'topics';
  return this.apiHandlerService.get(url);
 }

  /**
   * Add Content
   */
  addContent(myCon):any {
    localStorage.setItem('requireToken', 'true');
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'content/add';
    return this.apiHandlerService.save(url, myCon);
  };
  /**
  * Get chapter list
  */
  getGradeContent(gradeId){
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'content/' +gradeId;
    return this.apiHandlerService.get(url);
  }
} 