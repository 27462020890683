export class ApiEndpointConfig {
    public host: string;
	public port: number;
	public protocol: string;
	public apiUrl: string;

    constructor(){
        this.host = '';
        this.protocol = 'https';
        this.port = 4200;
        this.apiUrl =  ''
    }

    /**
	 * Returns the full path of API endpoint.
	 * @return {string} [Returns concatinated api enpoint url ]
	 */
	getAPIEndpoint(): string {
        return "https://prod-backend.stepapp.in/api"; 
	};
}
