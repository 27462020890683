import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {NgbModal, NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { AppRouteUrls } from '../../shared/models/app-routes.model';
import { LoginService } from '../../shared/services/login.service';
import { AlertService } from '../../shared/services/alert.service';
import { Login } from '../../shared/models/login.model';
import { SessionService } from '../../shared/services/session.service';
import { User } from '../../shared/models/user.model';
import { RegistrationService } from '../../shared/services/registration.service';


import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angular-6-social-login';
import { UtilityService } from '../../shared/services/utility.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  @ViewChild('content') private content;
  public login: Login;
  public user_name: FormControl;
  public password: FormControl;
  public user_type: FormControl;
  public user_mobile: FormControl;
  public school_name: FormControl;
  public isSubmitted = false;
  public isSocialSubmitted = false;
  public user: User;
  private modalRef: NgbModalRef;
  constructor(private router: Router,
    private loginService: LoginService,
    private socialAuthService: AuthService,
    private alertService: AlertService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    public modalService: NgbModal,
    private NgbActiveModal: NgbActiveModal,
    private registrationService: RegistrationService) {
    localStorage.setItem('login', 'false');
    this.login = new Login();
    this.user = new User();
  }

  ngOnInit() {
    this.createFormControls();
    // Validate school name filed when user type is school
    this.user_type.valueChanges.subscribe(value => {
      if (value==='school') {
        this.school_name.setValidators([Validators.required]);
      } else {
        this.school_name.setValidators(null);
      }
    });
  }

  /**
   * Creates form controls 
   */
  createFormControls(): any {
    this.user_name = new FormControl('', [
      Validators.required,
    ]);
    this.password = new FormControl(null, [
      Validators.required,
    ]);
    this.user_mobile = new FormControl('', [
      Validators.required,
    ]);
    this.user_type = new FormControl('', [
      Validators.required,
    ]);
    this.school_name = new FormControl('', [
    ]);
  };

  /**
   * Navigate customer to self registration
   */
  navigateToSelfRegistration(): any {
    this.router.navigate([AppRouteUrls.SELF_REGISTRATION]);
  };

  /**
   * Navigate customer to forgot password
   */
  navigateToForgotPassword(): any {
    this.router.navigate([AppRouteUrls.FORGOT_PASSWORD]);
  };

   /**
   * Validate register form fields
   */
   validateSocialRegisterFormFields(): boolean {
    if(!this.user_mobile.invalid && !this.user_type.invalid){
      if(this.user_type.value == 'school'){
        if(!this.school_name.invalid){
          return true;
        }else{
          return false;
        }
      }else{
        return true;
      }
    } else {
      return false;
    }
  };

  /**
   * User login with social platform
   * @param socialPlatform Social login flatform
   */
  socialSignIn(socialPlatform: any) {
    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    // User login with social platform
    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        this.sessionService.resetSession();
        if(userData.id){
          let data ={
            provider:userData.provider,
            provider_id:userData.id
          }
          
          this.loginService.socialLogin(data).subscribe(res => {
            // Login user to portal after login success with social platform if user exist in portal
            if (res.body.status === 'success') {
              this.sessionService.setContext(res.body);
              this.router.navigate([AppRouteUrls.DASHBOARD]);
              this.utilityService.isHeaderVisible.emit(true);
            }else if(res.body.status === 'error' && res.body.signup ==1) {

              // Register user to portal after login success with social platform if user not exist in portal
              var lastSpace = userData.name.lastIndexOf(' '); // last occurence of space
              var fname = userData.name.substring(0, lastSpace); // Paul Steve
              var lname = userData.name.substring(lastSpace + 1); // Panakkal
              this.user.first_name = fname;
              this.user.last_name = lname;
              this.user.user_email = userData.email;
              this.user.provider_id = userData.id;
              this.user.provider = userData.provider;
              this.user.isSocialLogin = true;
              // Open user details modal to take user information like, Mobile no and user role
              this.openSocialLogin(this.content);
            } else{
              this.alertService.error("In correct password");
            }
          }, (error: any) => {
            this.alertService.error("In correct password");
          });
        }
      }
    );
  };
  /**
   * Open Social login modal
   * @param content 
   */
  openSocialLogin(content) {
    this.modalRef = this.modalService.open(content);
  }

  closeLogin(){
    this.modalRef.close();
  }
  /**
   * Register user to portal after successfully login with social platform
   */
  socialRegister(){
    this.isSocialSubmitted = true;
    this.user.user_mobile = this.user_mobile.value;
    this.user.user_type = this.user_type.value;
    this.user.school_name = this.school_name.value;
    if(this.validateSocialRegisterFormFields()){
      this.registrationService.registerUser(this.user).subscribe((data: any) => {
        if(data.body.status === 'success'){
          this.closeLogin();
          this.sessionService.setContext(data.body);
          this.router.navigate([AppRouteUrls.DASHBOARD]);
          this.utilityService.isHeaderVisible.emit(true);
        }else{
          this.alertService.error('Login failed!');
        }
      }, (error: any) =>{
        this.alertService.error(error.error.message);
      })
    };
  }
  /**
   * Login to portal
   */
  loginToPortal(): any {
    this.isSubmitted = true;
    if (this.validateLoginFormFields()) {
      this.createLoginFormData();
      this.loginService.login(this.login).subscribe(res => {
        if (res.body.status === 'success') {
          this.sessionService.setContext(res.body);
          this.router.navigate([AppRouteUrls.DASHBOARD]);
          this.utilityService.isHeaderVisible.emit(true);
        }else{
          this.alertService.error("In correct password");
        }
      }, (error: any) => {
        this.alertService.error("In correct password");
      });
    }
  };

  /**
   * Validate login form fields
   */
  validateLoginFormFields(): any {
    if (!this.user_name.invalid && !this.password.invalid) {
      return true;
    } else {
      return false;
    }
  };
  /**
   * Creats 
   *  login form data
   */
  createLoginFormData(): any {
    this.login.user_name = this.user_name.value;
    this.login.password = this.password.value;
  };

} 