import { Component, OnInit, Input, EventEmitter, Output  } from '@angular/core';
import { Router } from '@angular/router';
import { AppRouteUrls } from '../../../shared/models/app-routes.model';
import { LoginService } from '../../../shared/services/login.service';
import { AlertService } from '../../../shared/services/alert.service';
import { SessionService } from '../../../shared/services/session.service';
import { UtilityService } from '../../../shared/services/utility.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  @Input() isSideBarHidden;
  @Output() toggleSidebar = new EventEmitter<string>();
  
  appRouteUrls = AppRouteUrls;
  constructor(private router: Router,
        private loginService: LoginService,
        private alertService: AlertService,
        public sessionService: SessionService,
        private utilityService: UtilityService) {}
        visible = false;
  ngOnInit() {
    
  }
  /**
   * Logout from portal
   */
  logoutFromPortal(): void {
    this.sessionService.resetSession();
    this.router.navigate([AppRouteUrls.LOGIN]);
    this.utilityService.isHeaderVisible.emit(false);
    this.utilityService.isSideBarVisible.emit(false);
    localStorage.clear();
    
  }
  onSideBar() {
    this.toggleSidebar.next();
    //this.utilityService.isSideBarVisible.emit('');
  }
}
