import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SessionService } from "../../../../shared/services/session.service";
import { AlertService } from "../../../../shared/services/alert.service";
import { FormControl, Validators } from "@angular/forms";
import { SchoolTypesService } from "../school-types.service";
import { Schooltypes } from "../../../../shared/models/school-type.model";
@Component({
	selector: "app-create-school-types",
	templateUrl: "./create-school-types.html",
	styleUrls: ["./create-school-types.scss"]
})
export class CreateSchoolTypesComponent implements OnInit {
	public Schooltype: Schooltypes;
	public school_type: FormControl;
	public isSubmitted = false;
	school__title_type: any;
	constructor(
		private router: Router,
		private sessionService: SessionService,
		private alertService: AlertService,
		private schooltypesserv: SchoolTypesService
	) {
		this.school__title_type = new Schooltypes();
	}

	ngOnInit() {
		this.createFormControls();
	}
	createFormControls(): any {
		this.school_type = new FormControl("", [Validators.required]);
	}
	/**
	 * Creats a board form data
	 */
	createSchoolchainFormData(): any {
		this.school__title_type.school_type = this.school_type.value;
	}

	/**
	 * Get language list
	 */

	/**
	 * Validate form fields
	 */
	validateFormFields(): any {
		if (!this.school_type.invalid) {
			return true;
		} else {
			return false;
		}
	}
	/**
	 * create schoolchain
	 */
	createSchoolchain() {
		this.isSubmitted = true;
		if (this.validateFormFields()) {
			this.createSchoolchainFormData();
			this.schooltypesserv.createSchoolType(this.school__title_type).subscribe(
				(response: any) => {
					if (response.status === "success") {
						this.alertService.success("School Type Created Successfully");
						this.router.navigate(["./school-types"]);
					} else {
						this.alertService.error("School Type Creation Failed");
					}
				},
				(error: any) => {
					this.alertService.error(
						"School Type already exist with the same name"
					);
				}
			);
		}
	}
	cancel() {
		this.router.navigate(["./school-types"]);
	}
}
