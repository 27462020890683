import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class SessionService {

  private loggedIn = false;

  constructor() { }

  /**
   * Set user is logged in.
   * @param isLoggedIn 
   */
  setIsUserLoggedIn(isLoggedIn: string){
    localStorage.setItem("is_logged_in", isLoggedIn);
  };

  /**
   * Get user is logged in.
   */
  getIsUserLoggedIn(){
    return localStorage.getItem("is_logged_in");
  };

  /**
   * Checks if user is logged in.
   */
  isLoggedIn() {
    this.loggedIn = (this.getIsUserLoggedIn() === "True") ? true : false;
    return this.loggedIn;
  };

  /**
   * Get current logged in users role is admin or not
   */
  getIsCustomerAdmin() {
    return localStorage.getItem("is_customer_admin");
  };

  /**
   * Sets current logged in user role
   * @param isCustomerAdmin 
   */
  setIsCustomerAdmin(isCustomerAdmin: string) {
    localStorage.setItem("is_customer_admin", isCustomerAdmin);
  };
  /**
   * Returns customer admin is logged in or not 
   */
  isCustomerAdmin(): boolean {
    this.loggedIn = (localStorage.getItem('is_customer_admin') === 'true') ? true : false;
    return this.loggedIn;
  }
  
  /**
   * Provides access token of current logged in user from session.
   */
  getSessionId() {
    return localStorage.getItem("session_id");
  };

  /**
   * Sets access toekn of user.
   * @param accessToken 
   */
  setSessionId(sessionId: string) {
    localStorage.setItem("session_id", sessionId);
  };

  /**
   * Provides access token of current logged in user from session.
   */
  getSfId() {
    return localStorage.getItem("sf_id");
  };

  /**
   * Sets access toekn of user.
   * @param accessToken 
   */
  setSfId(sfId: string) {
    localStorage.setItem("sf_id", sfId);
  };

  /**
   * Provides First name of current logged in user from session.
   */
  getFirstName() {
    return localStorage.getItem("first_name");
  };
  /**
   * Provides Last name of current logged in user from session.
   */
   getLastName() {
    return localStorage.getItem("last_name");
  };

  /**
   * Sets access toekn of user.
   * @param accessToken 
   */
  setFirstName(first_name: string) {
    localStorage.setItem("first_name", first_name);
  };

  /**
   * Create session for current logged in user.
   * @param {LoginModel} loginModel
   */
  setContext(data: any) {
    this.loggedIn = true;
    let userLoggedIn = "True";
    this.setIsUserLoggedIn(userLoggedIn);
    const token = data.access_token;
    const decode = jwt_decode(token);
    localStorage.setItem("accessTokan",data.access_token);
    localStorage.setItem("userType",decode.user_type);
    localStorage.setItem("user_name",decode.user_name);
    localStorage.setItem("first_name",decode.first_name);
    localStorage.setItem("last_name",decode.last_name);
  };
  setContextSocial(data: any) {
    this.loggedIn = true;
    let userLoggedIn = "True";
    this.setIsUserLoggedIn(userLoggedIn);
    localStorage.setItem("user_name",data.user_name);
    localStorage.setItem("first_name",data.first_name);
    localStorage.setItem("last_name",data.last_name);
    //this.setIsCustomerAdmin(data.success.userdetails.IsCustomerAdmin__c);
  };
  /**
   * Reset session of current logged in user.
   */
  resetSession() {
    this.loggedIn = false;
    localStorage.removeItem("session_id");
    localStorage.removeItem("is_logged_in");
    localStorage.removeItem("user_name");
    localStorage.removeItem("sf_id");
    localStorage.removeItem("is_customer_admin");
    localStorage.removeItem("userType");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
  };

  isAdmin(){
    if(this.getIsUserLoggedIn() && localStorage.getItem("userType")=== "admin"){
      return true;
    }else{
      return false;
    }
  }

  isReseller(){
    if(this.getIsUserLoggedIn() && localStorage.getItem("userType")=== "reseller"){
      return true;
    }else{
      return false;
    }
  }

  isSchool(){
    if(this.getIsUserLoggedIn() && localStorage.getItem("userType")=== "school"){
      return true;
    }else{
      return false;
    }
  }

  isParent(){
    if(this.getIsUserLoggedIn() && localStorage.getItem("userType")=== "parent"){
      return true;
    }else{
      return false;
    }
  }

  isStudent(){
    if(this.getIsUserLoggedIn() && localStorage.getItem("userType")=== "student"){
      return true;
    }else{
      return false;
    }
  }
  isEduisfunAdmin(){
    if(this.getIsUserLoggedIn() && localStorage.getItem("userType")=== "eduisfunadmin"){
      return true;
    }else{
      return false;
    }
  }
}
