export class Languages {
    language:string;
    language_id: any = '';


    constructor() {
        this.language = "";
       this.language_id="";
    }
}
