export class ChangePassword {
    old_password:string;
    new_password :string;
    confirm_password:string;

    constructor() {
        this.old_password = "";
        this.new_password = "";
        this.confirm_password = "";
    }

}
