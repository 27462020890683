export class AppRoutes {
}

export enum AppRouteUrls {
    LANDING_PAGE = <any> '/',
    LOGIN = <any> 'login',
    SELF_REGISTRATION = <any> 'register',
    RESET_PASSWORD = <any> 'reset-password',
    FORGOT_PASSWORD = <any> 'forgot-password',
    DASHBOARD = <any> 'dashboard',
    TOPICLIST = <any> 'topic-list',
    CHAPTERLIST = <any> 'chapters',
    
}

export default AppRouteUrls;