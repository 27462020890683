import { Component, OnInit } from '@angular/core';;
import { SessionService } from '../../shared/services/session.service';
import { SchoolsService } from '../../shared/services/schools.service';
import { UserCount } from '../../shared/models/user-count.model';
import { LeaderboardService } from '../../shared/services/leaderboard.service';
import { AlertService } from '../../shared/services/alert.service';
import { ExcelService } from '../../shared/services/excel.service';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public schools: any;
  public usercount : UserCount;
  public from_date = new FormControl;
  public to_date = new FormControl;
  public school_id :any  = "all";
  public users: any;
  public filltered = false;
  public users_count= [];
  p: number = 1;

  //sorting table 
  key: string = 'username';'first_name';'last_name';'user_email';'user_mobile';'isActive';
  reverse: boolean = false;

  public recentCases: Array<any>;
  constructor(
    public SchoolsService: SchoolsService,
    public sessionService: SessionService,
    private alertService: AlertService,
    public ExcelService: ExcelService,
    public LeaderboardService : LeaderboardService
    ) {
      this.usercount = new UserCount();
   }

  ngOnInit() {
    this.getSchoolsList() 
  }

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  /**
   * Get School List 
   */

  getSchoolsList() {
    this.SchoolsService.getSchoolsList().subscribe((data: any) => {
      this.schools = data.data;
    }, (response: any) => {
    }); 
  };


 pad2(number) {
    return (number < 10 ? '0' : '') + number;
 
  }
  /**
   * Creats user data 
   */

  createUserFormData(): any {
    if(this.from_date.value == null){
      delete this.usercount.from_date;
    }
    else
    {
      this.usercount.from_date = this.from_date.value.year + '-' + this.pad2(this.from_date.value.month) + '-' + this.pad2(this.from_date.value.day);

    }
     if(this.to_date.value == null){
     delete  this.usercount.to_date;
    }
    else
    {
      this.usercount.to_date = this.to_date.value.year + '-' + this.pad2(this.to_date.value.month) + '-' + this.pad2(this.to_date.value.day);
    }   
    
    this.usercount.school_id = this.school_id;
  }

  /**
   * get registered users
   */
  getRegisterUsers() {
      this.createUserFormData();
      this.LeaderboardService.getRegisterUsers(this.usercount).subscribe((data: any) => {
        this.users_count = data.body.users_count; 
        this.users = data.body.data;   
        this.filltered = true;    
      },
      
      (response: any) => {
        this.filltered = true;
        this.users_count = [];
      });  
  };

   /**
   *
   * User Active
   * 
   */
    
  activateUser(userId){
      this.LeaderboardService.activeUser(userId).subscribe((response: any) => {
        if(response.body.status == "success") {
          this.alertService.success(response.body.message);
  
        }
        else if(response.body.status == "error") {  
          this.alertService.error(response.body.message);
          
        }
        else {
          this.alertService.error("Internal Server Error");
        }
    }
    );

    this.getRegisterUsers();
  }


  /**
   *
   * User Active
   * 
   */


  deactivateUser(userId){
    this.LeaderboardService.deactiveUser(userId).subscribe((response: any) => {
      if(response.body.status == "success") {
        this.alertService.success(response.body.message);

      }
      else if(response.body.status == "error") {  
        this.alertService.error(response.body.message);
        
      }
      else {
        this.alertService.error("Internal Server Error");
      }
  }
  
  );
  this.getRegisterUsers();
}

 /**
   *
   * User Csv Report
   * 
   */
  
  exportAsXLSX():void { 
    let data = this.users;
    for(let i=0; i < this.users.length; i++){
      delete data[i]['id'];
      delete data[i]['isActive']
  }
    this.ExcelService.exportAsExcelFile(data, 'Registered users');
 }
}
