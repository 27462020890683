import { Injectable } from '@angular/core';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';
import { ApiHandlerService } from '../services/api-handler.service';
import { Schools } from '../models/schools.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SerialKeyService {

  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;
  constructor(
    private httpClient: HttpClient,
    private apiHandlerService: ApiHandlerService) {
    this.apiEndpointdonfig = new ApiEndpointConfig();
    this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();
}

 /**
   * 
   * @param grades create serial key
   */
  createSerialKey(serialKey: Schools):any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'generate/serialkeys';
    return this.apiHandlerService.save(url, serialKey);
  };

  
  /**
  * Get Used and Unused Serial Keys 
  */

 getSerialKeys(school_id){
  let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'serialkeys'
  return this.apiHandlerService.save(url,{school_id});
}

/**
  * Get school serial keys
  */

getSchoolSerialKeys(){
  let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'serialkeys'
  return this.apiHandlerService.save(url, {});

}

  /**
   * 
   * Request serial key from sc\hool dashboard
   */
  requestSerialKey(requestKey):any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'school/skrequest'; 
    return this.apiHandlerService.save(url, requestKey);
  };

  /**
  * Get list of serialkeys requests
  */

 getSerialKeyRequestList(){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'school/skrequest/list';
    return this.apiHandlerService.get(url);

  }


  /**
  * Get Serial key request details using request id 
  */

  getSerialKeyRequestDetails(requestId){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'serialkeys/request/'+requestId ;
    return this.apiHandlerService.get(url);
  }

/**
  * modify serial key request
 */
  UpdateRequestKey(formData: any){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'serialkeys/request/update';
    return this.httpClient.post(url,formData)

  }

/**
  * Delete serial key request
  */

  deleteRequest(id){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'serialkeys/request/'+id;
    return this.apiHandlerService.delete(url);
  }


/**
  * Get List of serial key requests
  */

  getListOfSerialKeyRequests(){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'serialkeys/request';
    return this.apiHandlerService.get(url);

  }
 /**
  * Approve serial key request
  */

 approveRequest(_formData){
  const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'serialkeys/approval';
  return this.apiHandlerService.save(url,_formData);

}

}


