import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AlertService {

    private subject = new Subject<any>();
    private keepAfterNavigationChange = true;

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = true;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
    }

    /**
     * Shows the success message
     * @param message 
     * @param keepAfterNavigationChange 
     */
    success(message: string, keepAfterNavigationChange = true) {
        console.log(message)
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message });
        this.autoHideMessage();
    };

    /**
     * Shows the error message
     * @param message 
     * @param keepAfterNavigationChange 
     */
    error(message: string, keepAfterNavigationChange = true) {
        console.log(message);
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
        this.autoHideMessage();
    };

    hideMessage() {
        this.subject.next();
    }

    autoHideMessage(){
        setTimeout(function() {
            console.log("setTimeout",setTimeout);
            this.hideMessage();
        }.bind(this), 5000);
    }

    /**
     * Get the messgage 
     */
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    };
}