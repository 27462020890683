import { Component, OnInit } from '@angular/core';
import { GradesService } from '../../shared/services/grades.service';
import { SubjectsService } from '../../shared/services/subjects.service';
import { ChaptersService } from '../../shared/services/chapters.service';
import { LeaderboardService } from '../../shared/services/leaderboard.service';
import { ContentSetupService } from '../../shared/services/content-setup.service';

@Component({
  selector: 'app-leadershipboard',
  templateUrl: './leadershipboard.component.html',
  styleUrls: ['./leadershipboard.component.scss']
})
export class LeadershipboardComponent implements OnInit {
  p: number = 1;
  public boards;
  public grades;
  public subjects;
  public chapters;
  public schools;
  public selectedSubject: any = "";
  public selectedBoard: any = "";
  public selectedGrade: any = "";
  public selectedSchool: any = "";
  public selectedChapter: any = "";
  public allRank= [];
  public userRank= [];
  public filltered = false;
  
  //sorting table 
  key: string = 'username';'rank';'time_taken';'score';
  reverse: boolean = false;

  constructor(private gradesService: GradesService,
    public chapterservice: ChaptersService,
    public subjectService: SubjectsService,
    public contentsetupservice: ContentSetupService,
    public LeaderboardService : LeaderboardService) {
      
    }

  ngOnInit() {
    this.getBoardList();
  }


  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }

  /**
   * Get Board List 
   */

   getBoardList() {
    this.gradesService.getListOfBoards().subscribe((data: any) => {
      this.boards = data.data;
    }, (response: any) => {
      this.boards = [];
    });
  };

    /**
     * Get Grade List 
     */
  getGrades() {
    this.subjectService.getGrades(this.selectedBoard).subscribe((data: any) => {
      this.grades = data.data;
    }, (response: any) => {
      this.grades = [];
    });
  };

    /**
     * Get Schools List 
     */

     getSchools() {
      this.LeaderboardService.getSchools(this.selectedBoard).subscribe((data: any) => {
        this.schools = data.data;
      }, (response: any) => {
        this.schools = [];
      });
    };
  
   /**
    * Get Subject List 
    */
  getSubjects() {
    this.subjectService.getSubjects(this.selectedGrade).subscribe((data: any) => {
      this.subjects = data.data;
    }, (response: any) => {
      this.subjects = []
    });
  };

  /**
   * Get List of chapter
   */

  getChapterList() {
    this.contentsetupservice.getChapterList(this.selectedSubject).subscribe((data: any) => {
      this.chapters = data.data;
    }, (response: any) => {
      this.chapters = []
    });
  };

  /**
   * Get leaderboard data
   */

   getLeaderbordList() {
    let params = {
      board_id:this.selectedBoard,
      school_id:this.selectedSchool,
      grade_id:this.selectedGrade,
      subject_id:this.selectedSubject,
      chapter_id:this.selectedChapter,
      numberOfRecords:'all',
    }
    this.filltered = false;
    this.LeaderboardService.getLeaderbordList(params).subscribe((data: any) => {
      this.allRank = data.allRank;
      this.userRank = data.userRank;
      this.filltered = true;
    }, (response: any) => { 
      this.filltered = true;
      this.allRank = [];
      this.userRank = [];
    });
  };
}
