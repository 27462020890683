import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { SelfRegistrationComponent } from "./components/self-registration/self-registration.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { SideBarComponent } from "./components/side-bar/side-bar.component";
//import { ActivateKeyComponent } from "./components/activate-key/activate-key.component";
import { AuthGuard } from "./auth.guard";
//import { ContentSetupComponent } from "./components/content-setup/content-setup.component";
//import { AssetBundleComponent } from "./components/asset-bundle/asset-bundle.component";
//import { ChapterCreationComponent } from "./components/chapter-creation/chapter-creation.component";
//import { GradesComponent } from "./components/grades/grades.component";
//import { SubjectsComponent } from "./components/subjects/subjects.component";
//import { SubjectListComponent } from "./components/subjects/subject-list/subject-list.component";
//import { SubjectUpdateComponent } from "./components/subjects/subject-update/subject-update.component";
//import { TopicListComponent } from "./components/asset-bundle/topic-list/topic-list.component";
//import { TopicUpdateComponent } from "./components/asset-bundle/topic-update/topic-update.component";
import { LeadershipboardComponent } from "./components/leadershipboard/leadershipboard.component";
//import { ChaptersListComponent } from "./components/chapters-list/chapters-list.component";
//import { ChapterUpdateComponent } from "./components/chapter-update/chapter-update.component";
//import { BoardsListComponent } from "./components/boards/boards-list/boards-list.component";
//import { UpdateBoardComponent } from "./components/boards/update-board/update-board.component";
//import { CreateBoardComponent } from "./components/boards/create-board/create-board.component";
import { LanguagesListComponent } from "./components/languages/language-list/languages-list.component";
import { UpdateLanguageComponent } from "./components/languages/update-language/update-language.component";
import { CreateLanguagesComponent } from "./components/languages/create-language/create-language.component";
import { SchoolChainsComponent } from "./components/school-chain/school-chains.component";
import { CreateSchoolChainComponent } from "./components/school-chain/create-schoolchain/create-schoolchain.component";
import { UpdateSchoolChainComponent } from "./components/school-chain/update-schoolchain/update-schoolchain.component";
//import { GradesListComponent } from "./components/grades/grades-list/grades-list.component";
//import { GradeUpdateComponent } from "./components/grades/grade-update/grade-update.component";
import { SchoolsComponent } from "./components/schools/schools.component";
import { SchoolsListComponent } from "./components/schools/schools-list/schools-list.component";
import { UpdateSchoolsComponent } from "./components/schools/update-schools/update-schools.component";
//import { SerialKeyListComponent } from "./components/activate-key/serial-key-list/serial-key-list.component";
//import { RequestSerialKeyComponent } from "./components/activate-key/request-serial-key/request-serial-key.component";
import { ParentSignUpComponent } from "./components/parent-sign-up/parent-sign-up.component";
import { ProfileSettingComponent } from "./components/profile-setting/profile-setting.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { PerformanceReportComponent } from "./components/performance-report/performance-report.component";
//import { RequestKeyListComponent } from "./components/request-key-list/request-key-list.component";
//import { UpdateRequestKeyListComponent } from "./components/update-request-key-list/update-request-key-list.component";
//import { AdRequestListComponent } from "./components/ad-request-list/ad-request-list.component";
import { CreateSchoolTypesComponent } from "./components/schools/school-types/create-school-types/create-school-types";
import { UpdateSchoolTypesComponent } from "./components/schools/school-types/update-school-types/update-school-types.component";

import { from } from "rxjs/observable/from";
import { SchoolTypesComponent } from "./components/schools/school-types/school-types.component";
const routes: Routes = [
	{ path: "login", component: LoginComponent, canActivate: [AuthGuard] },
	{ path: "login", component: LoginComponent, canActivate: [AuthGuard] },
	{
		path: "register",
		component: SelfRegistrationComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "reset-password/:code/:expiry",
		component: ResetPasswordComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "reset-password/:code",
		component: ResetPasswordComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "forgot-password",
		component: ForgotPasswordComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "dashboard",
		component: DashboardComponent,
		canActivate: [AuthGuard]
	},
	{ path: "side-bar", component: SideBarComponent, canActivate: [AuthGuard] },
	{ path: "leadership-board", component: LeadershipboardComponent, canActivate: [AuthGuard] },
	{
		path: "languages",
		component: LanguagesListComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "update-languages/:id",
		component: UpdateLanguageComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "schoolchain",
		component: SchoolChainsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "create-schoolchain",
		component: CreateSchoolChainComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "update-schoolchain/:id",
		component: UpdateSchoolChainComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "create-languages",
		component: CreateLanguagesComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "create-schools",
		component: SchoolsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "schools-list",
		component: SchoolsListComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "update-school/:id",
		component: UpdateSchoolsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "parent-register",
		component: ParentSignUpComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "profile-setting",
		component: ProfileSettingComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "update-password",
		component: ChangePasswordComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "performance-report",
		component: PerformanceReportComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "school-types",
		component: SchoolTypesComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "create-school-types",
		component: CreateSchoolTypesComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "update-school-types/:id",
		component: UpdateSchoolTypesComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "**",
		pathMatch: "full",
		redirectTo: "dashboard",
		canActivate: [AuthGuard]
	}
	//{path: "content-setup", component: ContentSetupComponent, canActivate: [AuthGuard] }, 
	//{path: "activate-key", component: ActivateKeyComponent, canActivate: [AuthGuard] }, 
	//{path: "asset-bundle", component: AssetBundleComponent, canActivate: [AuthGuard] },
	//{path: "chapter-creation", component: ChapterCreationComponent, canActivate: [AuthGuard] },
	//{ path: "grades", component: GradesComponent, canActivate: [AuthGuard] },
	//{path: "create-subject", component: SubjectsComponent, canActivate: [AuthGuard] },
	//{path: "subject-list", component: SubjectListComponent, canActivate: [AuthGuard] },
	//{path: "subject-update/:id", component: SubjectUpdateComponent, canActivate: [AuthGuard] },
	//{path: "topic-list", component: TopicListComponent, canActivate: [AuthGuard] },
	//{path: "topic-update/:id", component: TopicUpdateComponent, canActivate: [AuthGuard] },
	//{path: "chapters", component: ChaptersListComponent, canActivate: [AuthGuard] },
	//{path: "chapter-update/:id", component: ChapterUpdateComponent, canActivate: [AuthGuard] },
	//{ path: "boards", component: BoardsListComponent, canActivate: [AuthGuard] },
	//{path: "update-board/:id", component: UpdateBoardComponent, canActivate: [AuthGuard] },
	//{path: "create-board", component: CreateBoardComponent, canActivate: [AuthGuard] },
	//{path: "grade-list", component: GradesListComponent, canActivate: [AuthGuard] },
	//{path: "update-grade/:id", component: GradeUpdateComponent, canActivate: [AuthGuard] },
	//{path: "serial-key-list", component: SerialKeyListComponent, canActivate: [AuthGuard] }, 
	//{path: "request-key", component: RequestSerialKeyComponent, canActivate: [AuthGuard] },
	//{path: "request-list", component: RequestKeyListComponent, canActivate: [AuthGuard] }, 
	//{path: "update-request-key/:id", component: UpdateRequestKeyListComponent, canActivate: [AuthGuard] },
	//{path: "ad-request-list", component: AdRequestListComponent, canActivate: [AuthGuard] },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
