import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../../../shared/services/languages.service';
import { SessionService } from '../../../shared/services/session.service';
import { Languages } from '../../../shared/models/languages.model';
import { AlertService } from '../../../shared/services/alert.service';
import { GradesService } from '../../../shared/services/grades.service'
import { FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-create-languages',
  templateUrl: './create-language.component.html',
  styleUrls: ['./create-language.component.scss']
})
export class CreateLanguagesComponent implements OnInit {
  public languages: Languages;
  public language: FormControl;
  public language_id: any;
  public isSubmitted = false;
  constructor(private router: Router,
    private languagesService: LanguageService,
    private sessionService: SessionService,
    private alertService: AlertService,
    private gradesService: GradesService) {
      this.languages = new Languages();
     }

  ngOnInit() {
    this.createFormControls();
  }
  createFormControls(): any {
    this.language = new FormControl("", [
      Validators.required]);
      
    this.language_id = new FormControl("", [
      Validators.required]);
      
  }
  /**
   * Creats a board form data
   */
  createBoardFormData(): any {
    this.languages.language = this.language.value
  
  }

  /**
 * Get language list
 */



  /**
   * Validate form fields
   */
   validateFormFields(): any {
    if (!this.language.invalid) {
      return true;
    } else {
      return false;
    }
  };
  /**
   * create board
   */
  createBoard() {
    this.isSubmitted = true;
    if(this.validateFormFields()){
      this.createBoardFormData();
      this.languagesService.createLanguage(this.languages).subscribe((response: any) => {
        if (response.status === 'success') {
          this.alertService.success('Language Created Successfully');
          this.router.navigate(['./languages']);
        }
        else{
          this.alertService.error("Langugae Creation Failed");
        }   
      }, (error: any) => {
        this.alertService.error("Language already exist with the same name");
      });
    };
  }
  cancel(){
    this.router.navigate(['./languages'])
  }
}
