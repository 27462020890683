export class UserProfile {
    first_name:string;
    last_name: string;
    user_email: string;
    user_mobile: string;

    constructor() {
        this.first_name = "";
        this.last_name = "";
        this.user_email = "";
        this.user_mobile = "";
   }
}
