import { Component, OnInit } from "@angular/core";
import { BoardsService } from "../../../shared/services/boards.service";
import { AlertService } from "../../../shared/services/alert.service";
import { LanguageService } from "../../../shared/services/languages.service";
@Component({
	selector: "app-boards-list",
	templateUrl: "./languages-list.component.html",
	styleUrls: ["./languages-list.component.scss"]
})
export class LanguagesListComponent implements OnInit {
	public languagesList;
	p: number = 1;

	//sorting table
	key: string = "language";
	reverse: boolean = false;

	constructor(
		public BoardsService: BoardsService,
		private alertService: AlertService,
		private languageService: LanguageService
	) {}

	ngOnInit() {
		this.getLanguageList();
	}

	/**
	 * sort table data in reverse order
	 */

	sort(key) {
		this.key = key;
		this.reverse = !this.reverse;
	}

	/**
	 * Get Board List
	 */

	getLanguageList() {
		this.languageService.getListOfLanguage().subscribe(
			(data: any) => {
				console.log("kll,,", data.data);
				this.languagesList = data.data;
			},
			(response: any) => {}
		);
	}

	/**
	 *
	 * Delete board
	 */

	deleteLanguage(id) {
		this.languageService.deleteLanguage(id).subscribe(
			(response: any) => {
				console.log("response", response);
				if (response.status === "success") {
					this.alertService.success("Langugae Deleted Successfully");
					this.getLanguageList();
				} else {
					this.alertService.error("Langugae Deletion Failed");
				}
			},
			(error: any) => {
				console.log("error", error);
				this.alertService.error("Langugae Deletion Failed");
			}
		);
	}
}
