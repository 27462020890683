import { Component, OnInit } from "@angular/core";
import { SchoolTypesService } from "./school-types.service";
import { HttpClient } from "@angular/common/http";
import { AlertService } from "../../../shared/services/alert.service";

@Component({
	selector: "app-school-types",
	templateUrl: "./school-types.component.html",
	styleUrls: ["./school-types.component.scss"]
})
export class SchoolTypesComponent implements OnInit {
	p: number = 1;
	public schoolTypeList: any[] = [];
	//sorting table
	key: string = "board_name";
	reverse: boolean = false;

	constructor(
		private schoolTypesService: SchoolTypesService,
		private http: HttpClient,
		private alertService: AlertService
	) {}

	ngOnInit() {
		this.getSchoolData();
	}
	getSchoolData() {
		this.schoolTypesService.getSchoolTypeList().subscribe(Response => {
			if (Response.status == "success") {
				this.schoolTypeList = Response.data;
				console.log(this.schoolTypeList);
			}
		});
	}
	deleteSchoolType(id) {
		this.schoolTypesService.deleteSchoolType(id).subscribe(
			(res: any) => {
				console.log("res......res", res);
				if (res.status == "success") {
					this.alertService.success("SchoolType Deleted Successfully ");
					this.getSchoolData();
				} else {
					this.alertService.error("SchoolType Deletion Failed");
				}
			},
			(error: any) => {
				console.log("error", error);
				this.alertService.error("SchoolType Deletion Failed");
			}
		);
	}
}
