import { Injectable } from '@angular/core';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';
import { ApiHandlerService } from '../services/api-handler.service';
import { Subject } from '../models/subject.model';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class SubjectsService {
  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;

  constructor(private apiHandlerService: ApiHandlerService,
    private httpClient: HttpClient) {
    this.apiEndpointdonfig = new ApiEndpointConfig();
    this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();
  }

  /**
  * 
  * get Grades
  */

  getGrades(boardId) {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'grade-byboard/' + boardId;
    return this.apiHandlerService.get(url);
  }

   /**
  * 
  * get subjects 
  */

  getSubjects(gradeId) {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'subjects/' + gradeId;
    return this.apiHandlerService.get(url);
  }
   /**
  * 
  * create new subject 
  */


  createSubject(subjects: Subject): any {
    localStorage.setItem('requireToken', 'true');
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'subjects';
    return this.apiHandlerService.save(url, subjects);
  };

  /**
  * 
  * Get list of subject in details 
  */

 getSubjectList() {
  localStorage.setItem('requireToken', 'true');
  let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'subjects';
  return this.apiHandlerService.get(url);
 }


 /**
  * 
  * Get subjects of subject ID 
  */
 
 getSubjectById(subjectId){
  let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'subject/'+subjectId ;
  return this.apiHandlerService.get(url);

 }

 /**
   * 
   * Update subject
   */
  
  updateSubject(formData: any, subjectId) {
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'subject/'+subjectId;
    return this.httpClient.post(url,formData)
  }

  /**
   * 
   * Delete Subject
   */

  deleteSubject(id){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'subject/'+id;
    return this.apiHandlerService.delete(url);

  }

}
