import { Injectable } from '@angular/core';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';
import { HttpClient } from '@angular/common/http';
import { ApiHandlerService } from '../services/api-handler.service';

@Injectable()
export class AssetBundleService {

  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;

  constructor(private httpClient: HttpClient,
    private apiHandlerService: ApiHandlerService) {
    this.apiEndpointdonfig = new ApiEndpointConfig();
    this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();
  }

  /**
   * 
   * @param Topic create Topic API service 
   */
  createTopic(formData: any) {
    localStorage.setItem('requireToken', 'true');
    console.log(JSON.stringify(formData));
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'create/topic';       //line 4
    return this.httpClient.post(url, formData)
  }

  /**
  * 
  * Get list of topics in details 
  */

  getTopicList() {
    localStorage.setItem('requireToken', 'true');
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'topic/list';       //line 4
    return this.apiHandlerService.get(url);
  }

  /**
  * 
  * Get topics by ID 
  */

  getTopicById(topicId){
    localStorage.setItem('requireToken', 'true');
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'topic/'+topicId;        //line 4
    return this.apiHandlerService.get(url);
  }


  /**
  * 
  * Update Topics
  */
  
  updateTopic(formData:any,topicId,){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'topic/update/' +topicId;       //line 4
    return this.httpClient.post(url,formData)
  }

   /**
  * 
  * Delete Topics
  */

 deleteTopic(id){
  let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'topic/'+id;
  return this.apiHandlerService.delete(url);

 }

 /**
   * 
   * Remove individual topic asset file 
   */

  removeTopic(type,field,topicId){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'asset/'+type+'/'+field+'/'+topicId    ;
    return this.apiHandlerService.delete(url);

  }

}
