import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UserProfileService } from '../../shared/services/user-profile.service';
import { AlertService } from '../../shared/services/alert.service';
import { UserProfile} from '../../shared/models/user-profile.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-setting',
  templateUrl: './profile-setting.component.html',
  styleUrls: ['./profile-setting.component.scss']
})
export class ProfileSettingComponent implements OnInit {
  public first_name: any;
  public last_name: any;
  public user_email: any;
  public user_mobile: any;
  public userprofile: any;
  public isSubmitted = false;
  public formData = new FormData();

  constructor(private UserProfileService:UserProfileService,
    private router: Router,
    private alertService: AlertService) { 
    this.userprofile = new UserProfile();
    }

  ngOnInit() {
   this.getUserProfile();
   this.createFormControls();
  }

     /**
      * Create form control data
      */

  createFormControls(): any {
    this.first_name = new FormControl("", [
      Validators.required]);
    this.last_name = new FormControl("", [
      Validators.required]);
    this.user_email = new FormControl("", [
      Validators.required]);
    this.user_mobile = new FormControl("", [
      Validators.required]);
  }

  /**
   * Validate form fields
   */

  validateFormFields(): any {
    if (!this.first_name.invalid && !this.last_name.invalid && !this.user_email.invalid && !this.user_mobile.invalid) {
      return true;
    } else {
      return false;
    }
  };

   /**
   * Creats a user form data
   */
  createUserFormData(): any {
    this.userprofile.first_name = this.first_name.value;
    this.userprofile.last_name = this.last_name.value;
    this.userprofile.user_email = this.user_email.value;
    this.userprofile.user_mobile = this.user_mobile.value;   
  
  }

  /**
   *  Get user’s profile details
   */
  
  getUserProfile() {
    localStorage.setItem('requireToken', 'true');
    this.UserProfileService.getUserProfile().subscribe((data: any) => {
      let getData = data.data;
      //console.log(getData);
      this.first_name.setValue(getData.first_name);
      this.last_name.setValue (getData.last_name);
      this.user_email.setValue (getData.user_email);
      this.user_mobile.setValue (getData.user_mobile);
    }, (response: any) => {
      console.log(response);
    });

  }

    /**
     *  Update user profile details
    */

    updateUserProfile(){
      this.isSubmitted = true;
      if (this.validateFormFields()) {
        this.createUserFormData()
        this.UserProfileService.updateUserProfile(this.userprofile).subscribe(
          (response: any) => {
            // console.log(response.body);
            if (response.body.status == "success") {
              this.alertService.success(response.body.message);
              this.router.navigate(['/dashboard']);
            }
            else if(response.body.status=="error"){
              this.alertService.error(response.body.message);
            }
            else {
              this.alertService.error("Internal Server Error");
            } 
          }
          );
      }
  }

  cancel(){
    this.router.navigate(['/dashboard']);
  }

}
