import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BoardsService } from '../../../shared/services/boards.service';
import { AlertService } from '../../../shared/services/alert.service';
import { FormControl, Validators } from '@angular/forms';
import { Boards } from '../../../shared/models/boards.model';
import { Languages } from '../../../shared/models/languages.model';
import  {LanguageService} from '../../../shared/services/languages.service'


@Component({
  selector: 'app-update-board',
  templateUrl: './update-language.component.html',
  styleUrls: ['./update-language.component.scss']
})
export class UpdateLanguageComponent implements OnInit {
  public LangId: any
  public languages;
  public language_name: any;
  public isSubmitted = false;
  //public formData = new FormData();

  constructor(private route: ActivatedRoute,
    private router: Router,
    public BoardsService: BoardsService,
    private alertService: AlertService,
    private langService:LanguageService) {
      this.languages = new Languages();
    }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.LangId = +params['id'];

    });
    this.getlangById();
    this.createFormControls();
  }
  createFormControls(): any {
    this.language_name = new FormControl("", [
      Validators.required]);
  }
  getlangById() {
    localStorage.setItem('requireToken', 'true');
    this.langService.getlangById(this.LangId).subscribe((data: any) => {
      let getData = data.data;
      //this.board_name.value = getData.board_name;
      this.language_name.setValue(getData.language);
    }, (response: any) => {
      console.log(response);
    });
  };
  /**
   * Validate form fields
   */
   validateFormFields(): any {
    if (!this.language_name.invalid) {
      return true;
    } else {
      return false;
    }
  };
  /**
   * Creats a board form data
   */
   createBoardFormData(): any {
    this.languages.language = this.language_name.value
  }

  /**
   * Update a board form data
   */
  UpdateLanguage() {
    this.isSubmitted = true;
    if(this.validateFormFields()){
      //let _formData = new FormData();
      //_formData.append('board_name', this.board_name.value);
      this.createBoardFormData();
      this.langService.updateLanguage(this.languages, this.LangId).subscribe(
        (response: any) => {

          if (response.status === 'success') {
            this.alertService.success('Language Updated Successfully');
            this.router.navigate(['/languages']);
          }
          else {
            this.alertService.error("Language Updation Failed");
          }
        }, (error: any) => {
          this.alertService.error("Language Updation Failed");
        });
    }
  }
   /**
   * cancel Board form 
   */
  cancel(){
    this.router.navigate(['/languages']);
  }

}
