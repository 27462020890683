import { Component, OnInit } from '@angular/core';
import { SchoolsService } from '../../../shared/services/schools.service';
import { AlertService } from '../../../shared/services/alert.service';


@Component({
  selector: 'app-schools-list',
  templateUrl: './schools-list.component.html',
  styleUrls: ['./schools-list.component.scss']
})
export class SchoolsListComponent implements OnInit {
  public schools;
  p: number = 1;

  //sorting table 
  key: string = 'school_name';'board_name';'city';
  reverse: boolean = false;
  constructor(public SchoolsService: SchoolsService,
    private alertService: AlertService) { }

  ngOnInit() {
   this.getSchoolsList()
  }

  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }

   /**
   * Get School List 
   */
  getSchoolsList() {
    this.SchoolsService.getSchoolsList().subscribe((data: any) => {
      this.schools = data.data;
    }, (response: any) => {
    }); 
  };

  /**
   * 
   * Delete School
   */
  
  deleteSchool(id){
    this.SchoolsService.deleteSchool(id).subscribe((response: any) => {
      if (response.status === 'success') {
        this.alertService.success('School Deleted Successfully');
        this.getSchoolsList();
      } else {
        this.alertService.error("School Deletion Failed");
      }
    }, (error: any) => {
      console.log("error",error);
      this.alertService.error("School Deletion Failed");
    });
  }

}
