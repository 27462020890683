import { Injectable } from '@angular/core';
import { ApiHandlerService } from './api-handler.service';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';
import { Myprofile } from '../models/myprofile.model';
import { AdminUser } from '../models/admin-user.model';

@Injectable()
export class UsersService {

  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;

  constructor(private apiHandlerService: ApiHandlerService) {
    this.apiEndpointdonfig = new ApiEndpointConfig();
    this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();  
  }

  /**
   * Get users list
   */
  getUsers():any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + APIUrls.PORTAL + APIUrls.URL_SEPARATOR + 'users/';
    return this.apiHandlerService.get(url);
  };

  /**
   * 
   * @param offset 
   * @param columnName 
   * @param sortType 
   */
  getUsersBySearchingAndOffset(searchValue: string, offset: number, columnName: string, sortType:string){
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + APIUrls.PORTAL + APIUrls.URL_SEPARATOR + 'users/?searchParam='+ searchValue + '&offset='+ offset.toString() + '&sort='+ columnName + '&sortType='+sortType;
    return this.apiHandlerService.get(url);
  }

  /**
   * 
   * @param userId 
   */
  deleteUserBuId(userId: string):any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + APIUrls.PORTAL + APIUrls.URL_SEPARATOR + 'users/' + userId +'/';
    return this.apiHandlerService.delete(url);
  };

  /**
   * Get current user details
   */
  getCurrentUserDetails():any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + APIUrls.PORTAL + APIUrls.URL_SEPARATOR + 'profile/';
    return this.apiHandlerService.get(url);
  };

  /**
   * 
   * @param myprofile 
   */
  updateMyProfile(myprofile: Myprofile):any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + APIUrls.PORTAL + APIUrls.URL_SEPARATOR + 'profile/';
    return this.apiHandlerService.update(url, myprofile);
  };

  /**
   * 
   * @param adminUser 
   */
  createUserByAdmin(adminUser: AdminUser):any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + APIUrls.PORTAL + APIUrls.URL_SEPARATOR + 'users/';
    return this.apiHandlerService.save(url, adminUser);
  };

  /**
   * 
   * @param myprofile 
   */
  updateUserByAdmin(adminUser: any, id: string):any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + APIUrls.PORTAL + APIUrls.URL_SEPARATOR + 'users/' + id + '/';
    return this.apiHandlerService.update(url, adminUser);
  };
}