import { Injectable } from '@angular/core';
import { ApiHandlerService } from '../services/api-handler.service';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';
import { Login } from '../models/login.model';
import { Observable } from 'rxjs';

@Injectable()
export class LoginService {

  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;

  constructor(private apiHandlerService: ApiHandlerService) {
      this.apiEndpointdonfig = new ApiEndpointConfig();
      this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();  
  }

  /**
   * 
   * @param login   
   */ 
  login(login: Login):Observable<any> {
    const body = {
      username : login.user_name,
      password : login.password
    }
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'users/login';
    return this.apiHandlerService.save(url, body);
  };

  /**
   * 
   * @param Social login   
   */ 
   socialLogin(login):Observable<any> {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'users/login';
    return this.apiHandlerService.save(url, login);
  };

  /**
   * 
   * @param login 
   */
  logout():any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'logout/';
    return this.apiHandlerService.save(url, {});
  };

  /**
   *  
   * @param email 
   */
  forgotPassword(email: any):any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + APIUrls.PORTAL + APIUrls.URL_SEPARATOR + 'auth/forgotpassword/';
    return this.apiHandlerService.save(url, email);
  };

  /**
   * 
   * @param login 
   */
  checkValidCodeForResetPassword(code: string):any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + APIUrls.PORTAL + APIUrls.URL_SEPARATOR + 'forgotpassword/' + code;
    return this.apiHandlerService.get(url);
  };

  /**
   * Varify user after self register
   */
  verifyUser(code: string, expiry: string):any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + APIUrls.PORTAL + APIUrls.URL_SEPARATOR + 'auth/verification?code=' + code+ '&' + 'expiry='+ expiry;
    return this.apiHandlerService.get(url);
  };

  /**
   * Set user password      
   */
  setPassword(password: any, confirmpassword: any, code: string):any {
    let payloadData = {"code": code, "password": password, "confirmpassword": confirmpassword};
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + APIUrls.PORTAL + APIUrls.URL_SEPARATOR + 'auth/setpassword/';
    return this.apiHandlerService.save(url, payloadData);
  };

  /**
   * 
   * @param password 
   */
  resetPassword(password: any, code: string):any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + APIUrls.PORTAL + APIUrls.URL_SEPARATOR + 'forgotpassword/' + code + APIUrls.URL_SEPARATOR;
    return this.apiHandlerService.save(url, password);
  };

}
