import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiEndpointConfig } from "../../shared/models/api-endpoint-config.model";
import { APIUrls } from "../../shared/models/api-request.model";
import { ApiHandlerService } from "../../shared/services/api-handler.service";

@Injectable()
export class SchoolChainsService {
	private apiEndpoint: string;
	private apiEndpointdonfig: ApiEndpointConfig;
	constructor(
		private httpClient: HttpClient,
		private apiHandlerService: ApiHandlerService
	) {
		this.apiEndpointdonfig = new ApiEndpointConfig();
		this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();
	}

	/**
	 *
	 * Schoolchain board
	 */
	createSchoolchain(formData: any) {
		let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + "schoolchain/add";
		return this.httpClient.post(url, formData);
	}
	/**
	 *
	 * Update SchoolChain
	 */
	updateSchoolChain(formData: any, boardId) {
		let url =
			this.apiEndpoint +
			APIUrls.URL_SEPARATOR +
			"schoolchain/update/" +
			boardId;
		return this.httpClient.post(url, formData);
	}

	/**
	 *
	 * Get list of school Chain in details
	 */

	getSchoolChainList() {
		let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + "schoolchain/list";
		console.log(url);
		return this.apiHandlerService.get(url);
	}

	/**
	 * Get board by boardId
	 */
	getSchoolChainById(schId) {
		let url =
			this.apiEndpoint + APIUrls.URL_SEPARATOR + "schoolchain/details/" + schId;
		return this.apiHandlerService.get(url);
	}

	/**
	 * Delete board
	 */

	deleteSchoolChain(id) {
		let url =
			this.apiEndpoint + APIUrls.URL_SEPARATOR + "schoolchain/delete/" + id;
		return this.apiHandlerService.postWithParams(url, {});
	}
}
