import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { AppRoutingModule } from "./app-routing.module";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NgxDeleteConfirmModule } from "ngx-delete-confirm";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbModule, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

import {
	SocialLoginModule,
	AuthServiceConfig,
	GoogleLoginProvider,
	FacebookLoginProvider
} from "angular-6-social-login";

// Services
// Application services
import { AppRoutingService } from "./app-routing.service";
import { SessionService } from "./shared/services/session.service";
import { UtilityService } from "./shared/services/utility.service";
import { ApiHandlerService } from "./shared/services/api-handler.service";
import { MessagingService } from "./shared/services/messaging.service";
import { ValidationService } from "./shared/services/validation.service";
import { AlertService } from "./shared/services/alert.service";
import { HttpInterceptorsService } from "./shared/services/http-interceptors.service";
import { LoadingIndicatorService } from "./shared/services/loading-indicator.service";
import { LoginService } from "./shared/services/login.service";
import { RegistrationService } from "./shared/services/registration.service";
import { UsersService } from "./shared/services/users.service";
import { ChaptersService } from "./shared/services/chapters.service";
import { AssetBundleService } from "./shared/services/asset-bundle.service";
import { SubjectsService } from "./shared/services/subjects.service";
import { GradesService } from "./shared/services/grades.service";
import { ContentSetupService } from "./shared/services/content-setup.service";
import { BoardsService } from "./shared/services/boards.service";
import { LeaderboardService } from "./shared/services/leaderboard.service";
import { SchoolsService } from "./shared/services/schools.service";
import { SerialKeyService } from "./shared/services/serial-key.service";
import { UserProfileService } from "./shared/services/user-profile.service";
import { ChangePasswordService } from "./shared/services/change-password.service";
import { ExcelService } from "./shared/services/excel.service";
import { LanguageService } from "./shared/services/languages.service";
import { SchoolChainsService } from "./components/school-chain/school-chains.service";
// import { ConfirmationDialogService } from './shared/services/confirmation-dialog.service';
import { OrderModule } from "ngx-order-pipe";

//Auth Guard
import { AuthGuard } from "./auth.guard";

//Components
import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { AlertComponent } from "./components/shared/alert/alert.component";
import { CreateSchoolTypesComponent } from "./components/schools/school-types/create-school-types/create-school-types";
import { UpdateSchoolTypesComponent } from "./components/schools/school-types/update-school-types/update-school-types.component";
import { ControlMessagesComponent } from "./components/shared/control-messages/control-messages.component";
import { LoaderComponent } from "./components/shared/loader/loader.component";
import { HeaderComponent } from "./components/layout/header/header.component";
import { SelfRegistrationComponent } from "./components/self-registration/self-registration.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
//import { ActivateKeyComponent } from "./components/activate-key/activate-key.component";
import { SideBarComponent } from "./components/side-bar/side-bar.component";
//import { ContentSetupComponent } from "./components/content-setup/content-setup.component";
//import { AssetBundleComponent } from "./components/asset-bundle/asset-bundle.component";
//import { ChapterCreationComponent } from "./components/chapter-creation/chapter-creation.component";
//import { GradesComponent } from "./components/grades/grades.component";
//import { SubjectsComponent } from "./components/subjects/subjects.component";
//import { TopicListComponent } from "./components/asset-bundle/topic-list/topic-list.component";
//import { TopicUpdateComponent } from "./components/asset-bundle/topic-update/topic-update.component";
import { LeadershipboardComponent } from "./components/leadershipboard/leadershipboard.component";
//import { ChaptersListComponent } from "./components/chapters-list/chapters-list.component";
//import { ChapterUpdateComponent } from "./components/chapter-update/chapter-update.component";
//import { BoardsListComponent } from "./components/boards/boards-list/boards-list.component";
//import { CreateBoardComponent } from "./components/boards/create-board/create-board.component";
//import { UpdateBoardComponent } from "./components/boards/update-board/update-board.component";
import { LanguagesListComponent } from "./components/languages/language-list/languages-list.component";
import { CreateLanguagesComponent } from "./components/languages/create-language/create-language.component";
import { UpdateLanguageComponent } from "./components/languages/update-language/update-language.component";
//import { SubjectListComponent } from "./components/subjects/subject-list/subject-list.component";
import { SchoolChainsComponent } from "./components/school-chain/school-chains.component";
import { CreateSchoolChainComponent } from "./components/school-chain/create-schoolchain/create-schoolchain.component";
import { UpdateSchoolChainComponent } from "./components/school-chain/update-schoolchain/update-schoolchain.component";
//import { GradesListComponent } from "./components/grades/grades-list/grades-list.component";
//import { SubjectUpdateComponent } from "./components/subjects/subject-update/subject-update.component";
//import { GradeUpdateComponent } from "./components/grades/grade-update/grade-update.component";
import { SchoolsComponent } from "./components/schools/schools.component";
import { SchoolsListComponent } from "./components/schools/schools-list/schools-list.component";
import { UpdateSchoolsComponent } from "./components/schools/update-schools/update-schools.component";
//import { SerialKeyListComponent } from "./components/activate-key/serial-key-list/serial-key-list.component";
//import { RequestSerialKeyComponent } from "./components/activate-key/request-serial-key/request-serial-key.component";
import { ParentSignUpComponent } from "./components/parent-sign-up/parent-sign-up.component";
import { ProfileSettingComponent } from "./components/profile-setting/profile-setting.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { PerformanceReportComponent } from "./components/performance-report/performance-report.component";
//import { RequestKeyListComponent } from "./components/request-key-list/request-key-list.component";
//import { UpdateRequestKeyListComponent } from "./components/update-request-key-list/update-request-key-list.component";
//import { AdRequestListComponent } from "./components/ad-request-list/ad-request-list.component";
import { SchoolTypesComponent } from "./components/schools/school-types/school-types.component";
import { SchoolTypesService } from "./components/schools/school-types/school-types.service";
// import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
export function getAuthServiceConfigs() {
	let config = new AuthServiceConfig([
		{
			id: FacebookLoginProvider.PROVIDER_ID,
			provider: new FacebookLoginProvider("349870182304707")
		},
		{
			id: GoogleLoginProvider.PROVIDER_ID,
			provider: new GoogleLoginProvider(
				"268758062099-3rjnqgf4272bdu32jfji2ktq6vhdeufv.apps.googleusercontent.com"
			)
		}
	]);
	return config;
}

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		AlertComponent,
		ControlMessagesComponent,
		LoaderComponent,
		HeaderComponent,
		SelfRegistrationComponent,
		ResetPasswordComponent,
		ForgotPasswordComponent,
		DashboardComponent,
		SideBarComponent,
		LeadershipboardComponent,
		LanguagesListComponent,
		CreateLanguagesComponent,
		UpdateLanguageComponent,
		SchoolChainsComponent,
		UpdateSchoolChainComponent,
		CreateSchoolChainComponent,
		SchoolsListComponent,
		SchoolsComponent,
		SchoolsListComponent,
		UpdateSchoolsComponent,
		ParentSignUpComponent,
		ProfileSettingComponent,
		ChangePasswordComponent,
		PerformanceReportComponent,
		SchoolTypesComponent,
		CreateSchoolTypesComponent,
		UpdateSchoolTypesComponent
		//ConfirmationDialogComponent,
		//RequestKeyListComponent,
		//UpdateRequestKeyListComponent,
		//AdRequestListComponent,
		//SerialKeyListComponent,
		//RequestSerialKeyComponent,
		//SubjectListComponent,
		//GradesListComponent,
		//SubjectUpdateComponent,
		//GradeUpdateComponent,
		//ChaptersListComponent,
		//ChapterUpdateComponent,
		//BoardsListComponent,
		//CreateBoardComponent,
		//UpdateBoardComponent,
		//ContentSetupComponent,
		//AssetBundleComponent,
		//ChapterCreationComponent,
		//GradesComponent,
		//SubjectsComponent,
		//TopicListComponent,
		//TopicUpdateComponent,
		//ActivateKeyComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		NgSelectModule,
		FormsModule,
		ReactiveFormsModule,
		SocialLoginModule,
		NgxPaginationModule,
		OrderModule,
		Ng2SearchPipeModule,
		NgxDeleteConfirmModule.forRoot(),
		NgbModule.forRoot()
	],
	entryComponents: [
		//  ConfirmationDialogComponent,
	],
	providers: [
		BsModalService,
		BsModalRef,
		AppRoutingService,
		AuthGuard,
		SessionService,
		UtilityService,
		ApiHandlerService,
		LoadingIndicatorService,
		MessagingService,
		ValidationService,
		AlertService,
		LoginService,
		ChaptersService,
		AssetBundleService,
		RegistrationService,
		GradesService,
		LeaderboardService,
		SubjectsService,
		ContentSetupService,
		BoardsService,
		SchoolsService,
		SerialKeyService,
		UsersService,
		UserProfileService,
		ChangePasswordService,
		ExcelService,
		LanguageService,
		SchoolChainsService,
		SchoolTypesService,
		// ConfirmationDialogService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptorsService,
			multi: true
		},
		NgbActiveModal,
		{
			provide: AuthServiceConfig,
			useFactory: getAuthServiceConfigs
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
