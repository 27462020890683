import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
import { SessionService } from '../app/shared/services/session.service';
import { AppRouteUrls } from './shared/models/app-routes.model';

@Injectable()
export class AuthGuard implements CanActivate {
  restrictedUrls: any = {
    "/": true,
    "/login": true,
    "/register": true,
    "/forgot-password":true,
    "/reset-password":true
  };
  constructor(private sessionService: SessionService,
    private router: Router){
  }
  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.sessionService.isLoggedIn() && this.restrictNavigation(state.url)) {
    	return true;
    }else if (this.sessionService.isLoggedIn() && this.restrictNavigation(state.url)) {
      this.router.navigate(['**']);
    	return false;
    }else if (!this.sessionService.isLoggedIn() && !this.restrictNavigation(state.url)) {
      this.router.navigate([AppRouteUrls.LOGIN.toString()]);
    	return false;
    }else if ((this.sessionService.isLoggedIn())) {
      return true;
    }
  }

    // Controls restriction's of url
    restrictNavigation(url: any) {
      if (this.restrictedUrls[url]) {
        return true;
      }
      return false;
    };
}
