import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/Rx';
import { SessionService } from '../services/session.service';
 
@Injectable()
export class ApiHandlerService {
  
  constructor(private httpClient: HttpClient,
              private sessionService: SessionService) {  }

  /**
   * 
   * @param url Executes GET api
   */
  get(url: string): Observable<any>{
    return this.httpClient.get(url, {observe: 'response' })
    .map((res: HttpResponse<any>) => this.handleResponse(res))
    .catch((error: HttpErrorResponse) => this.handleError(error));
  };

  /**
   * 
   * @param url Executes GET api with params
   */
   postWithParams(url: string, params:any): Observable<any>{
    return this.httpClient.post(url, params, {observe: 'response'})
    .map((res: HttpResponse<any>) => this.handleResponse(res))
    .catch((error: HttpErrorResponse) => this.handleError(error));
  };
  
  /**
   * 
   * @param url Execites POST api
   * @param data 
   */
  save(url: string, data: any): Observable<any>{
    
    return this.httpClient.post(url ,data, { observe: 'response' })
;
  };
  
  /**
   * 
   * @param url Executes PUT api
   * @param data 
   */
  update(url: string, data: any): Observable<any>{
    return this.httpClient.put(url, data, { observe: 'response' })
    .map((res: HttpResponse<any>) => this.handleResponse(res))
    .catch((error: HttpErrorResponse) => this.handleError(error));
  };

  /**
   * 
   * @param url Executes DELETE api
   */
  delete(url: string): Observable<any>{
    return this.httpClient.delete(url, { observe: 'response' })
    .map((res: HttpResponse<any>) => this.handleResponse(res))
    .catch((error: HttpErrorResponse) => this.handleError(error));
  };

  /**
   * Handles api response
   * @param response 
   */
  handleResponse(response){
    if((!this.sessionService.getSessionId() || this.sessionService.getSessionId() === null) && (!this.sessionService.getSfId() || this.sessionService.getSfId() === null)){
      this.sessionService.setSessionId(response.headers.get('sfdc_session_id'));
      
    }
    if (response.status === 204) {
      return {};
    } else {
      return response.body;
    }
  };

  /**
   * Handles api error
   * @param error 
   */
  handleError(error: HttpErrorResponse){
    if (error.status == 401) {
        return Observable.throw(error.error);
    } else if (error.status == 500) {
        return Observable.throw(error.error);
    } else {
     return Observable.throw(error.error || 'Something has gone wrong. Please contact support.');
   }
  };
}