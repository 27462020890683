import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SchoolChainsService } from "../school-chains.service";
import { SessionService } from "../../../shared/services/session.service";
import { Schoolchains } from "../../../shared/models/schoolchain.model";
import { AlertService } from "../../../shared/services/alert.service";
import { FormControl, Validators } from "@angular/forms";
@Component({
	selector: "app-create-languages",
	templateUrl: "./create-schoolchain.component.html",
	styleUrls: ["./create-schoolchain.component.scss"]
})
export class CreateSchoolChainComponent implements OnInit {
	public Schoolchains: Schoolchains;
	public schoolchains_type: FormControl;
	public isSubmitted = false;
	school__title_type: any;
	constructor(
		private router: Router,
		private sessionService: SessionService,
		private alertService: AlertService,
		private schoolchainserv: SchoolChainsService
	) {
		this.school__title_type = new Schoolchains();
	}

	ngOnInit() {
		this.createFormControls();
	}
	createFormControls(): any {
		this.schoolchains_type = new FormControl("", [Validators.required]);
	}
	/**
	 * Creats a board form data
	 */
	createSchoolchainFormData(): any {
		this.school__title_type.school_chain_title = this.schoolchains_type.value;
	}

	/**
	 * Get language list
	 */

	/**
	 * Validate form fields
	 */
	validateFormFields(): any {
		if (!this.schoolchains_type.invalid) {
			return true;
		} else {
			return false;
		}
	}
	/**
	 * create schoolchain
	 */
	createSchoolchain() {
		this.isSubmitted = true;
		if (this.validateFormFields()) {
			this.createSchoolchainFormData();
			this.schoolchainserv.createSchoolchain(this.school__title_type).subscribe(
				(response: any) => {
					if (response.status === "success") {
						this.alertService.success("schoolchain Created Successfully");
						this.router.navigate(["./schoolchain"]);
					} else {
						this.alertService.error("schoolchain Creation Failed");
					}
				},
				(error: any) => {
					this.alertService.error(
						"schoolchain already exist with the same name"
					);
				}
			);
		}
	}
	cancel() {
		this.router.navigate(["./schoolchain"]);
	}
}
