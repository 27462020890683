export class Login {
    user_name: string;
    password: string;


    constructor() {
        this.user_name = '',
        this.password = ''
    }
}
