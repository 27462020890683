export class UserCount {
    from_date: string;
    to_date: string;
    school_id: string;

    constructor() {
        this.from_date = "";
        this.to_date = "";
        this.school_id = "";

   }
}

//2019-04-23