import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ChangePassword } from '../../shared/models/change-password.model';
import { ChangePasswordService } from '../../shared/services/change-password.service';
import { AlertService } from '../../shared/services/alert.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public old_password: any;
  public new_password: any;
  public confirm_password: any;
  public isSubmitted = false;
  public changepassword: any;
  public formData = new FormData();

  constructor(private ChangePasswordService: ChangePasswordService,
    private router: Router,
    private alertService: AlertService) {
    this.changepassword = new ChangePassword();
  }

  ngOnInit() {
    this.createFormControls();
  }

  /**
  * Create form control data
  */

  createFormControls(): any {
    this.old_password = new FormControl("", [
      Validators.required]);
    this.new_password = new FormControl("", [
      Validators.required]);
    this.confirm_password = new FormControl("", [
      Validators.required]);
  }

  /**
 * Validate form fields
 */

  validateFormFields(): any {
    if (!this.old_password.invalid && !this.new_password.invalid && !this.confirm_password.invalid) {
      return true;
    } else {
      return false;
    }
  };

  /**
  * Creats a password form data
  */
  createPasswordFormData(): any {
    this.changepassword.old_password = this.old_password.value;
    this.changepassword.new_password = this.new_password.value;
    this.changepassword.confirm_password = this.confirm_password.value;

  }

  /**
   *  Update user password 
  */

 UpdatePassword() {
    this.isSubmitted = true;
    if (this.validateFormFields()) {
        this.createPasswordFormData()
        this.ChangePasswordService.updatePassword(this.changepassword).subscribe(
          (response: any) => {
            if (response.body.status == "success") {
              this.alertService.success(response.body.message);
              this.router.navigate(['/dashboard']);
            }
            else if(response.body.status=="error"){
              this.alertService.error(response.body.message);
            }
            else {
              this.alertService.error("Internal Server Error");
            } 
          }
      );
    }

   }

   cancel(){
    this.router.navigate(['/dashboard']);
   }
   
  }
