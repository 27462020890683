export class User {
	first_name: string
	last_name: string;
	password: string;
	user_mobile: string;
    user_email: string;
    user_type:string;
    school_name:string;
    isSocialLogin:boolean;
    provider:string;
    provider_id:string;
    
    constructor() {
        this.first_name = "";
        this.last_name = "";
        this.password = "";
        this.user_mobile = "";
        this.user_email = "";
        this.user_type = "";
        this.school_name ="";
        this.isSocialLogin = false;
        this.provider = "";
        this.provider_id="";
    }
}
