import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { SchoolChainsService } from "../school-chains.service";
import { Schoolchains } from "../../../shared/models/schoolchain.model";
import { AlertService } from "../../../shared/services/alert.service";
import { FormControl, Validators } from "@angular/forms";

@Component({
	selector: "app-update-board",
	templateUrl: "./update-schoolchain.component.html",
	styleUrls: ["./update-schoolchain.component.scss"]
})
export class UpdateSchoolChainComponent implements OnInit {
	public Schoolchains: Schoolchains;

	public SchId: any;
	public shchool_chain_title: any;
	public schoolChian: any;
	public isSubmitted = false;
	//public formData = new FormData();

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private alertService: AlertService,
		private schoolserv: SchoolChainsService
	) {
		this.schoolChian = new Schoolchains();
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			this.SchId = +params["id"];
		});
		this.getschById();
		this.createFormControls();
	}
	createFormControls(): any {
		this.shchool_chain_title = new FormControl("", [Validators.required]);
	}
	getschById() {
		localStorage.setItem("requireToken", "true");
		this.schoolserv.getSchoolChainById(this.SchId).subscribe(
			(data: any) => {
				let getData = data.data;
				console.log("hello", getData);
				//this.board_name.value = getData.board_name;
				this.shchool_chain_title.setValue(getData.school_chain_title);
			},
			(response: any) => {
				console.log(response);
			}
		);
	}
	/**
	 * Validate form fields
	 */
	validateFormFields(): any {
		if (!this.shchool_chain_title.invalid) {
			return true;
		} else {
			return false;
		}
	}
	/**
	 * Creats a schoolChian form data
	 */
	createschoolChianFormData(): any {
		this.schoolChian.school_chain_title = this.shchool_chain_title.value;
	}

	/**
	 * Update a SchoolChain form data
	 */
	UpdateSchoolChain() {
		this.isSubmitted = true;
		if (this.validateFormFields()) {
			//let _formData = new FormData();
			//_formData.append('board_name', this.board_name.value);
			this.createschoolChianFormData();
			this.schoolserv.updateSchoolChain(this.schoolChian, this.SchId).subscribe(
				(response: any) => {
					if (response.status === "success") {
						this.alertService.success("schoolchain Updated Successfully");
						this.router.navigate(["/schoolchain"]);
					} else {
						this.alertService.error("schoolchain Updation Failed");
					}
				},
				(error: any) => {
					this.alertService.error("schoolchain Updation Failed");
				}
			);
		}
	}
	/**
	 * cancel Board form
	 */
	cancel() {
		this.router.navigate(["/schoolchain"]);
	}
}
