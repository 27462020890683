import { Injectable } from '@angular/core';
import { HttpEvent, HttpHeaders, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { SessionService } from '../services/session.service';
import { LoadingIndicatorService } from './loading-indicator.service';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';

@Injectable()
export class HttpInterceptorsService implements HttpInterceptor{
  
  headers: HttpHeaders;
  
  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;
  

  constructor(private sessionService: SessionService,
              private loadingIndicatorService: LoadingIndicatorService) {
                this.apiEndpointdonfig = new ApiEndpointConfig();
                this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();
                this.headers = new HttpHeaders();
                  this.headers.append('access-control-allow-origin', '*');
          }

  /**                                         
   * Creates the required headers for request
   */
  createAuthorizationHeader(req): void {
    this.headers = this.headers.set('Access-Control-Allow-Origin','*');
    // this.headers = this.headers.set('Content-Type', 'multipart/form-data');
    this.headers = this.headers.set('Accept', '*/*');
    this.headers = this.headers.set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
    this.headers = this.headers.set('Access-Control-Allow-Headers', 'origin,X-Requested-With,content-type,accept');
    this.headers = this.headers.set('Access-Control-Allow-Credentials', 'true');
    if(localStorage.getItem('accessTokan'))
    { 
      const token = localStorage.getItem('accessTokan');
      const tokenkey = "Bearer "+token;
      this.headers = this.headers.set('Authorization', tokenkey );
      localStorage.setItem('requireToken','false');
    }
  };
    
  /**
   * 
   * @param req Request to be cloned
   * @param next Handling request
   */
  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
      this.createAuthorizationHeader(req);
      const apiRequest = req.clone({headers:this.headers});

      this.loadingIndicatorService.showLoader();
      return next.handle(apiRequest).do
      ((response) => {
        if (response instanceof HttpResponse) {
          this.loadingIndicatorService.hideLoader();
        }
      },
      (error) => {
        this.loadingIndicatorService.hideLoader();
      });
  };
};



