import { Component, OnInit } from '@angular/core';
import { SessionService } from '../app/shared/services/session.service';
import { UtilityService } from './shared/services/utility.service';
import { Router, Event } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isSideBarHidden = true;
  className = 'col-md-10 col-sm-10';
  sideBarClassName = 'col-md-2 col-sm-2';
  isHeaderVisible = true;
  constructor(private sessionService: SessionService,
    private utilityService: UtilityService, private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (this.router.url === '' || this.router.url === '/' || this.router.url === '/login' || this.router.url === '/logout' || this.router.url === '/register') {
        this.isSideBarHidden = true;
      } else {
        this.isSideBarHidden = false;
      }
    });
  }
  ngOnInit() {
  }

  toggleSidebar() {
    this.isSideBarHidden = !this.isSideBarHidden;
  }
  onMinimize() {
    this.className = (this.className === 'col-md-11 col-sm-11') ? 'col-md-10 col-sm-11' : 'col-md-11 col-sm-11';
    this.sideBarClassName = (this.sideBarClassName === 'col-md-1 col-sm-1') ? 'col-md-2 col-sm-2' : 'col-md-1 col-sm-1';
  }
}
