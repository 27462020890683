import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { SchoolTypesService } from "../school-types.service";
import { AlertService } from "../../../../shared/services/alert.service";
import { Schooltypes } from "../../../../shared/models/school-type.model";
import { FormControl, Validators } from "@angular/forms";

@Component({
	selector: "app-update-school-types",
	templateUrl: "./update-school-types.component.html",
	styleUrls: ["./update-school-types.component.scss"]
})
export class UpdateSchoolTypesComponent implements OnInit {
	public Schooltype: Schooltypes;

	public SchId: any;
	public shchool_type: any;
	public schoolChian: any;
	public isSubmitted = false;
	//public formData = new FormData();

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private alertService: AlertService,
		private schooltypesserv: SchoolTypesService
	) {
		this.Schooltype = new Schooltypes();
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			this.SchId = +params["id"];
		});
		this.getschById();
		this.createFormControls();
	}
	createFormControls(): any {
		this.shchool_type = new FormControl("", [Validators.required]);
	}
	getschById() {
		localStorage.setItem("requireToken", "true");
		this.schooltypesserv.getSchoolTypeById(this.SchId).subscribe(
			(data: any) => {
				let getData = data.data;
				console.log("hello", getData);
				//this.board_name.value = getData.board_name;
				this.shchool_type.setValue(getData.school_type);
			},
			(response: any) => {
				console.log(response);
			}
		);
	}
	/**
	 * Validate form fields
	 */
	validateFormFields(): any {
		if (!this.shchool_type.invalid) {
			return true;
		} else {
			return false;
		}
	}
	/**
	 * Creats a schoolChian form data
	 */
	createschoolChianFormData(): any {
		this.Schooltype.school_type = this.shchool_type.value;
	}

	/**
	 * Update a SchoolChain form data
	 */
	UpdateSchoolChain() {
		this.isSubmitted = true;
		if (this.validateFormFields()) {
			//let _formData = new FormData();
			//_formData.append('board_name', this.board_name.value);
			this.createschoolChianFormData();
			this.schooltypesserv
				.updateSchoolType(this.Schooltype, this.SchId)
				.subscribe(
					(response: any) => {
						if (response.status === "success") {
							this.alertService.success("Schooltype Updated Successfully");
							this.router.navigate(["/school-types"]);
						} else {
							this.alertService.error("Schooltype Updation Failed");
						}
					},
					(error: any) => {
						this.alertService.error("Schooltype Updation Failed");
					}
				);
		}
	}
	/**
	 * cancel Board form
	 */
	cancel() {
		this.router.navigate(["/school-types"]);
	}
}
