import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {

  constructor() { }

    static getValidatorErrorMessage(validatorName : string, labelName : string, validatorValue?: any) {
      let config = {
          'required': `${labelName} is required`,
          'invalidCreditCard': 'Is invalid credit card number',
          'invalidEmailAddress': 'Invalid email address',
          'invalidPassword': 'Invalid password. Password must be at least 8 characters long, and contain one num' +
                  'ber, one uppercase and one lowercase letter and one special character.',
          'minlength': `Minimum length ${validatorValue.requiredLength}`
      };

      return config[validatorName];
    }

   /**
     * Validates email
     * @param control
     */
    static emailValidator(control) {
        // RFC 2822 compliant regex
        if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            return null;
        } else {
            return {'invalidEmailAddress': true};
        }
    };

    /**
     * Validates password
     * @param control 
     */
    static passwordValidator(control) {
        // {8,100}           - Assert password is between 8 and 100 characters
        // (?=.*[0-9])       - Assert a string has at least one number
        if (control.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8})$/)) {
            return null;
        } else {
            return {'invalidPassword': true};
        }
    }
}
