export class ApiRequest {
}

// Controls all API Urls
export enum APIUrls {
    URL_SEPARATOR =  '/',
    DUMMY_DATA_TEST = 'posts',
    PORTAL = 'portal',
}

export default APIUrls;