import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiEndpointConfig } from "../../../shared/models/api-endpoint-config.model";
import { APIUrls } from "../../../shared/models/api-request.model";
import { ApiHandlerService } from "../../../shared/services/api-handler.service";

@Injectable()
export class SchoolTypesService {
	private apiEndpoint: string;
	private apiEndpointdonfig: ApiEndpointConfig;
	constructor(
		private httpClient: HttpClient,
		private apiHandlerService: ApiHandlerService
	) {
		this.apiEndpointdonfig = new ApiEndpointConfig();
		this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();
	}

	/**
	 *
	 * Create board
	 */
	createSchoolType(formData: any) {
		let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + "schooltype/add";
		return this.httpClient.post(url, formData);
	}
	/**
	 *
	 * Update board
	 */
	updateSchoolType(formData: any, boardId) {
		let url =
			this.apiEndpoint + APIUrls.URL_SEPARATOR + "schooltype/update/" + boardId;
		return this.httpClient.post(url, formData);
	}

	/**
	 *
	 * Get list of boards in details
	 */

	getSchoolTypeList() {
		let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + "schooltype/list";
		console.log(url);
		return this.apiHandlerService.get(url);
	}

	/**
	 * Get board by boardId
	 */
	getSchoolTypeById(boardId) {
		let url =
			this.apiEndpoint +
			APIUrls.URL_SEPARATOR +
			"schooltype/details/" +
			boardId;
		return this.apiHandlerService.get(url);
	}

	/**
	 * Delete board
	 */

	deleteSchoolType(id) {
		let url =
			this.apiEndpoint + APIUrls.URL_SEPARATOR + "schooltype/delete/" + id;
		return this.apiHandlerService.postWithParams(url, {});
	}
}
