import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { SchoolsService } from '../../../shared/services/schools.service';
import { GradesService } from '../../../shared/services/grades.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Schools } from '../../../shared/models/schools.model';

@Component({
  selector: 'app-update-schools',
  templateUrl: './update-schools.component.html',
  styleUrls: ['./update-schools.component.scss']
})
export class UpdateSchoolsComponent implements OnInit {
public schoolId:any;
public school_name:any;
public city :any;
public state : any;
public admin_first_name:any;
public admin_last_name :any;
public admin_email :any;
public admin_mobile :any;
public school:any;
public formData = new FormData()
public isSubmitted = false;
public board_id :any;
//public board_name :any;
public schools:any;
boards;

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private router: Router,
    private schoolsService: SchoolsService,
    private gradesService: GradesService) { 
    this.schools = new Schools();
    }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.schoolId = +params['id'];
    });
    this.getBoardList();
    this.getSchoolById();
    this.createFormControls();
  }
 /**
   * Creats a form controls       
   */

  createFormControls(): any {
    this.board_id = new FormControl("", [
      Validators.required]);
    this.school_name = new FormControl("", [
      Validators.required]);
    this.city = new FormControl("", [
      Validators.required]);
    this.state = new FormControl("", [
      Validators.required]);
    this.admin_first_name = new FormControl("", [
      Validators.required]);
    this.admin_last_name = new FormControl("", [
      Validators.required]);
    this.admin_email = new FormControl("", [
      Validators.required]);
    this.admin_mobile = new FormControl("", [
      Validators.required]);
      this.school = new FormControl("", [
        Validators.required]);
  }

  /**
   * Creats a School form data
   */
  createSchoolFormData(): any {
    this.schools.board_id = this.board_id.value;
    this.schools.school_name = this.school_name.value;
    this.schools.city = this.city.value;
    this.schools.state = this.state.value;
    this.schools.admin_first_name = this.admin_first_name.value;
    this.schools.admin_last_name = this.admin_last_name.value;
    this.schools.admin_email = this.admin_email.value;
    this.schools.admin_mobile = this.admin_mobile.value;
  }

  /**
   * Validate form fields
   */

  validateFormFields(): any {
    if ( !this.board_id.invalid && !this.school_name.invalid && !this.city.invalid && !this.state.invalid
      && !this.admin_first_name.invalid && !this.admin_last_name.invalid
      && !this.admin_email.invalid && !this.admin_mobile.invalid) {
      return true;
    } else {
      return false;
    }
  };

   /**
   * Get board list 
   */
  getBoardList() {
    this.gradesService.getListOfBoards().subscribe((data: any) => {
      this.boards = data.data;
    }, (response: any) => {
      console.log(response);
    });
  };

    /**
   * Get schools in details
   */

getSchoolById(){
  localStorage.setItem('requireToken', 'true');
    this.schoolsService.getSchoolById(this.schoolId).subscribe((data: any) => {
      //console.log('hi',data);
      let getData = data.data[0];
      // console.log('hiiii',getData.board_id)
      this.board_id.setValue(getData.board_id);
      //this.board_name.setValue (getData.board_name)
      this.school_name.setValue(getData.school_name);
      this.city.setValue(getData.city);
      this.state.setValue(getData.state);
      this.admin_first_name.setValue(getData.admin_first_name);
      this.admin_last_name.setValue(getData.admin_last_name);
      this.admin_email.setValue(getData.admin_email);
      this.admin_mobile.setValue(getData.admin_mobile);
    }, (response: any) => {
      console.log(response);
    });

}

/**
   * Update Schools 
 */

updateSchools(){
  this.isSubmitted = true;
    if (this.validateFormFields()) {
      this.createSchoolFormData()
      this.schoolsService.updateSchool(this.schools, this.schoolId).subscribe(
        (response: any) => {

          if (response.status === 'success') {
            this.alertService.success('School Updated Successfully');
            this.router.navigate(['/schools-list']);
          }
          else {
            this.alertService.error("School Updation Failed");
          }
        }, (error: any) => {
          this.alertService.error("School Updation Failed");
        });
    }
}

 /**
   * cancel subject form 
 */
  cancel(){
    this.router.navigate(['/subject-list']);
  }
}
