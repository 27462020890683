import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';
import { ApiHandlerService } from '../services/api-handler.service';
import { UserCount } from '../../shared/models/user-count.model';

@Injectable()

export class LeaderboardService {
  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;
  constructor(private httpClient: HttpClient,
    private apiHandlerService: ApiHandlerService) {
    this.apiEndpointdonfig = new ApiEndpointConfig();
    this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();
  }

  /**
  * 
  * Get list of schools by board id 
  */

  getSchools(boardId) {
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'schools/'+boardId;
    return this.apiHandlerService.get(url);
  }
  
  /**
  * 
  * Get students data
  */

  getLeaderbordList(params) {
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'student/leaderboard';
    return this.apiHandlerService.postWithParams(url, params);
  }

 /**
  * 
  * Get Percentage report for admin
 */

  getPercentageReport(params){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'user/performance';
    return this.apiHandlerService.postWithParams(url, params);

  }


 /**
  * 
  *  Get grades from school
 */

  getGradeschool(){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'school/grades';
    return this.apiHandlerService.get(url);

  }

  /**
  * 
  * Get Percentage report for admin
 */

  getSchoolPercentageReport(params){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'user/performance/school';
    return this.apiHandlerService.postWithParams(url, params);

  }

  /**
  * 
  * Get Registered users for admin
 */
  
    getRegisterUsers(usercount: UserCount){
      localStorage.setItem('requireToken', 'true');
      const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'users/signup/count';
      return this.apiHandlerService.save(url, usercount);
}

 /**
  * 
  * Activate User 
  * 
 */

 activeUser(userId){
  localStorage.setItem('requireToken', 'true');
  const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'user/activate/'+userId;
  return this.apiHandlerService.save(url,'');

 }

 deactiveUser(userId){
  localStorage.setItem('requireToken', 'true');
  const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'user/deactivate/'+userId;
  return this.apiHandlerService.save(url,'');

 }

}
