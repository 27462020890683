import { Component, OnInit } from "@angular/core";
import { SchoolChainsService } from "./school-chains.service";
import { HttpClient } from "@angular/common/http";
import { AlertService } from "../../shared/services/alert.service";

// import { AlertService } from '../../shared/services/alert.service';

@Component({
	selector: "app-school-chains",
	templateUrl: "./school-chains.component.html",
	styleUrls: ["./school-chains.component.scss"]
})
export class SchoolChainsComponent implements OnInit {
	p: number = 1;
	public schoolTypeList: any[] = [];
	//sorting table
	key: string = "school_chain_title";
	reverse: boolean = false;

	constructor(
		private SchoolChainsService: SchoolChainsService,
		private alertService: AlertService,
		private http: HttpClient // private alertService: AlertService,
	) {}

	sort(key) {
		this.key = key;
		this.reverse = !this.reverse;
	}
	ngOnInit() {
		this.getSchoolData();
	}
	getSchoolData() {
		this.SchoolChainsService.getSchoolChainList().subscribe(Response => {
			if (Response.status == "success") {
				this.schoolTypeList = Response.data;
				console.log(this.schoolTypeList);
			}
		});
	}

	deleteSchoolchain(id) {
		this.SchoolChainsService.deleteSchoolChain(id).subscribe(
			(response: any) => {
				console.log("response", response);
				if (response.status === "success") {
					this.alertService.success("Langugae Deleted Successfully");
					this.getSchoolData();
				} else {
					this.alertService.error("Langugae Deletion Failed");
				}
			},
			(error: any) => {
				console.log("error", error);
				this.alertService.error("Langugae Deletion Failed");
			}
		);
	}
}
