import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';
import { ApiHandlerService } from '../services/api-handler.service';

@Injectable()

export class ChaptersService {
  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;
  constructor(private httpClient: HttpClient,
    private apiHandlerService: ApiHandlerService) {
    this.apiEndpointdonfig = new ApiEndpointConfig();
    this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();
  }

   /**
   * 
   * send file to server 
   */
  sendFile(formData: any) {
    // alert(JSON.stringify(formData));
    localStorage.setItem('requireToken', 'true'); 
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'create/chapter';       //line 4
    return this.httpClient.post(url,formData)
  }

  /**
   * 
   * Update chapter 
   */
   updateChapter(formData: any, chapterId) {
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'chapter/update/'+chapterId;
    return this.httpClient.post(url,formData)
  }

  /**
  * 
  * Get list of chapters in details 
  */

  getChaptersList() {
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'chapter/list';
    return this.apiHandlerService.get(url);
  }

  /**
  * Get chapter by chapter id
  */
  getChapterById(chapterId){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'chapter/'+chapterId;
    return this.apiHandlerService.get(url);
  }

   /**
  * Delete Chapter 
  */

  deleteChapter(id){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'chapter/'+id;
    return this.apiHandlerService.delete(url);
  }
  
   /**
   * 
   * get json string from file
   */
   getJsonData(formData: any) {
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'getjsonfile';
    return this.httpClient.post(url,formData)
  }

  /**
   * 
   * Remove individual chapter asset file 
   */

  removeChapter (type,field,chapterId){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'asset/'+type+'/'+field+'/'+chapterId;
    return this.apiHandlerService.delete(url);

  }
}
