import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';
import { ApiHandlerService } from '../services/api-handler.service';

@Injectable()

export class BoardsService {
  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;
  constructor(private httpClient: HttpClient,
    private apiHandlerService: ApiHandlerService) {
    this.apiEndpointdonfig = new ApiEndpointConfig();
    this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();
  }

  /**
   * 
   * Create board
   */
   createBoard(formData: any) {
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'boards';
    return this.httpClient.post(url,formData)
  }
  /**
   * 
   * Update board 
   */
   updateBoard(formData: any, boardId) {
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'board/'+boardId;
    return this.httpClient.post(url,formData)
  }

  /**
  * 
  * Get list of boards in details 
  */

  getBoardsList() {
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'boards';
    console.log(url);
    return this.apiHandlerService.get(url);
  }

  /**
  * Get board by boardId
  */
  getBoardById(boardId){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'board/'+boardId;
    return this.apiHandlerService.get(url);
  }

  /**
  * Delete board
  */

  deleteBoard(id){
    let url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'board/'+id;
    return this.apiHandlerService.delete(url);

  }
  
}
