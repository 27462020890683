import { Injectable } from '@angular/core';
import { ApiHandlerService } from '../services/api-handler.service';
import { ApiEndpointConfig } from '../models/api-endpoint-config.model';
import { APIUrls } from '../models/api-request.model';
import { User } from '../models/user.model';


@Injectable()
export class RegistrationService {

  private apiEndpoint: string;
  private apiEndpointdonfig: ApiEndpointConfig;

  constructor(private apiHandlerService: ApiHandlerService) {
      this.apiEndpointdonfig = new ApiEndpointConfig();
      this.apiEndpoint = this.apiEndpointdonfig.getAPIEndpoint();  
  }
  /**
   * 
   * @param user 
   */
  registerUser(user: User):any {
    const url = this.apiEndpoint + APIUrls.URL_SEPARATOR + 'users/signup';
    return this.apiHandlerService.save(url, user);
  };
}
  