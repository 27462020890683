import { Injectable } from '@angular/core';

@Injectable()
export class LoadingIndicatorService {

  private loadingInprogress: boolean;

  constructor() { 
    this.loadingInprogress = false;
  }

  /**
   * Show loader when request is hitted and waiting for response.
   */
  showLoader(): void {
    this.loadingInprogress = true;
  };

  /**
   * Hide loader when recives the response from server.
   */
  hideLoader(): void {
    this.loadingInprogress = false;
  };

  /**
   * Loading progress status
   */
  isLoading() : boolean{
    return this.loadingInprogress;
  };
}