import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class UtilityService {

  constructor() { }
  isHeaderVisible = new EventEmitter<any>();
  isSideBarVisible = new EventEmitter<any>();
  isSideBarMinimize = new EventEmitter<any>();
  sidebarMinimizeButton = new EventEmitter<any>();
}
