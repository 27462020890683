import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ValidationService } from '../../../shared/services/validation.service';

@Component({
  selector: 'app-control-messages',
  templateUrl: './control-messages.component.html',
  styleUrls: ['./control-messages.component.scss']
})
export class ControlMessagesComponent implements OnInit {

  errorMessage: string;
  @Input() control: FormControl;
  @Input() labelName: string;
  @Input() isSubmitted;

  constructor() { }

  ngOnInit() {
  }

  /**
   * Get error message and render.
   */
  getErrorMessage() {
    let self = this;
    if(this.control){
    for (let propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && ((this.control.touched || this.control.dirty) || this.isSubmitted)) {
        return ValidationService.getValidatorErrorMessage(propertyName, this.labelName, this.control.errors[propertyName]);
      }
    }
  }
    return null;
  };
};